import { create } from 'zustand';
import { AggregatedCollectionItem, CollectibleType } from '@sportscardinvestor/schemas';
import { trackEvent } from '@/features/analytics/trackEvent';

interface ConvertCollectionItemGradeDialogState {
  collectionItemId: number | null;
  collectibleType: CollectibleType;
  initialNewGradeCollectibleId: number | null;
  close: () => void;
  onSuccess: OnConvertCollectionItemGradeSuccessFn | null;
}

type OnConvertCollectionItemGradeSuccessFn = (params: {
  collectibleType: CollectibleType;
  newGradeCollectibleId: number;
}) => void;

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useConvertCollectionItemGradeDialogStore = create<ConvertCollectionItemGradeDialogState>()((set) => ({
  collectionItemId: null,
  collectibleType: defaultCollectibleType,
  initialNewGradeCollectibleId: null,
  onSuccess: null,
  close: () =>
    set({ collectionItemId: null, collectibleType: defaultCollectibleType, initialNewGradeCollectibleId: null }),
}));

export interface ConvertCollectionItemGradeDialogProps {
  collectionItemId: number;
  collectibleType: CollectibleType;
  newGradeCollectibleId?: number | null;
  onSuccess?: null | ((params: { collectibleType: CollectibleType; newGradeCollectibleId: number }) => void);
}
export function showConvertCollectionItemGradeDialog({
  collectionItemId,
  collectibleType,
  newGradeCollectibleId,
  onSuccess = null,
}: ConvertCollectionItemGradeDialogProps) {
  useConvertCollectionItemGradeDialogStore.setState({
    collectibleType,
    collectionItemId,
    initialNewGradeCollectibleId: newGradeCollectibleId,
    onSuccess,
  });
  trackEvent({
    eventName: 'COLLECTION_CONVERT_GRADE_STARTED',
    collectibleType,
    collectionItemId,
  });
}

export function canConvertCollectionItemGrade({
  collectibleType,
  isCustom,
}: Pick<AggregatedCollectionItem, 'isCustom' | 'collectibleType'>) {
  return !isCustom && collectibleType !== 'sealed-wax-card';
}
