import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { CollectibleType, CollectionCategory } from '@sportscardinvestor/schemas';
import { trackEvent } from '../analytics/trackEvent';
import { ListCollectionCategoriesOutput, makeListCollectionCategoriesQueryKey } from './useCollectionCategories';
import { useListCollectionItemsKeyPrefix } from './deprecated_useListCollectionItems';
import { useAggregatedCollectionItemsListKeyPrefix } from './useAggregatedCollectionItemsList';
import { useAuthenticatedMMApiMutation, MmApiInput, MmApiOutput, mmApiClient } from 'services/mmApiX/index';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { showError, showSuccess } from 'services/toaster';
import { useState } from 'react';
import { useCollectionDimensionValuesKey } from './useCollectionDimensionValues';
import { useCollectionDimensionStatsKey } from './useCollectionDimensionStats';

export type CreateCollectionCategoriesInput = Exclude<
  MmApiInput['private']['collection']['categories']['createMultiple'],
  void
>;
export type CreateCollectionCategoriesOutput = MmApiOutput['private']['collection']['categories']['createMultiple'];
export type CreateCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['create'], void>;
export type UpdateCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['update'], void>;
export type DeleteCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['delete'], void>;

export default function useCollectionCategoryMutations() {
  const queryClient = useQueryClient();
  const [editingCategoryIds, setEditingCategoryIds] = useState<Set<number>>(new Set());

  const create = useMutation({
    mutationFn: (params: CreateCollectionCategoryInput) =>
      mmApiClient.private.collection.categories.create.mutate(params),
    mutationKey: ['add-collection-category'],
    onSuccess: (newCategory, params) => {
      updateItemsWithNewCategory({
        collectibleType: newCategory.collectibleType,
        queryClient,
        newCategories: [newCategory],
      });
      showSuccess({
        description: `Successfully added "${params.name}" collection category`,
      });
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_ADD_COMPLETED',
        collectibleType: params.collectibleType,
      });
    },
    onError: (_, params) => {
      showError({
        description: 'This category already exists.',
      });
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_ADD_FAILED',
        collectibleType: params.collectibleType,
      });
    },
  });
  const createMultiple = useAuthenticatedMMApiMutation(
    (params: CreateCollectionCategoriesInput) =>
      mmApiClient.private.collection.categories.createMultiple.mutate(params),
    {
      mutationKey: ['add-multiple-collection-category'],
      onSuccess: (createdCategories, { collectibleType }) => {
        updateItemsWithNewCategory({
          collectibleType,
          queryClient,
          newCategories: createdCategories.data.map(({ name, id }) => ({
            collectibleType,
            id,
            name,
          })),
        });
        showSuccess({
          description: `Successfully added "${createdCategories.data
            .map((v) => v.name)
            .join(', ')}" to collection categories`,
        });
        trackEvent({
          eventName: 'COLLECTION_CATEGORY_IMPORT_COMPLETED',
          collectibleType,
        });
      },
      onError: (_, { collectibleType }) => {
        showError({
          description: 'This category already exists.',
        });
        trackEvent({
          eventName: 'COLLECTION_CATEGORY_IMPORT_FAILED',
          collectibleType,
        });
      },
    }
  );
  const update = useMutation({
    mutationFn: (
      params: UpdateCollectionCategoryInput & {
        collectibleType: CollectibleType;
      }
    ) => mmApiClient.private.collection.categories.update.mutate(params),
    mutationKey: ['update-collection-category'],
    onMutate: (params) => {
      setEditingCategoryIds((prev) => new Set(prev).add(params.id));
    },
    onSuccess: (updatedCategory, { collectibleType }) => {
      updateItemsWithNewCategory({
        collectibleType,
        queryClient,
        newCategories: [
          {
            ...updatedCategory,
            collectibleType,
          },
        ],
      });
      queryClient.invalidateQueries([useAggregatedCollectionItemsListKeyPrefix]);
      showSuccess({
        description: `Successfully renamed collection category to "${updatedCategory.name}"`,
      });
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_EDIT_COMPLETED',
        collectibleType,
      });
    },
    onError: (_, params) => {
      showError({
        description: 'Failed to rename collection category. Please try again.',
      });
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_EDIT_FAILED',
        collectibleType: params.collectibleType,
      });
    },
    onSettled: (_data, _error, { id }) => {
      setEditingCategoryIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    },
  });
  const remove = useMutation({
    mutationFn: (
      params: DeleteCollectionCategoryInput & {
        collectibleType: CollectibleType;
      }
    ) => mmApiClient.private.collection.categories.delete.mutate(params),
    mutationKey: ['delete-collection-category'],
    onMutate: (params) => {
      setEditingCategoryIds((prev) => new Set(prev).add(params.id));
    },
    onSuccess: (_, { collectibleType, id }) => {
      updateItemsWithNewCategory({
        collectibleType,
        queryClient,
        deletedCategoryId: id,
      });
      queryClient.invalidateQueries([useAggregatedCollectionItemsListKeyPrefix]);
      queryClient.invalidateQueries([useListCollectionItemsKeyPrefix]);
      queryClient.invalidateQueries([useCollectionDimensionValuesKey]);
      queryClient.invalidateQueries([useCollectionDimensionStatsKey]);
      showSuccess({
        description: 'Successfully deleted collection category',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete collection category. Please try again.',
      });
    },
    onSettled: (_, __, { id }) => {
      setEditingCategoryIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    },
  });
  const removeWithConfirmation = async (
    params: DeleteCollectionCategoryInput & {
      collectibleType: CollectibleType;
    }
  ) => {
    trackEvent({
      eventName: 'COLLECTION_CATEGORY_DELETE_STARTED',
    });
    const confirmed = await waitForConfirmation({
      title: 'Delete collection category',
      description: 'Are you sure you want to delete this collection category?',
    });
    if (confirmed) {
      await remove.mutateAsync(params, {
        onSuccess: () => {
          trackEvent({
            eventName: 'COLLECTION_CATEGORY_DELETE_COMPLETED',
          });
        },
        onError: () => {
          trackEvent({
            eventName: 'COLLECTION_CATEGORY_DELETE_FAILED',
          });
        },
      });
    } else {
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_DELETE_CANCELLED',
      });
    }
    return confirmed;
  };

  return {
    create,
    createMultiple,
    removeWithConfirmation,
    update,
    editingCategoryIds,
  };
}

function updateItemsWithNewCategory({
  queryClient,
  newCategories,
  deletedCategoryId,
  collectibleType,
}: {
  queryClient: QueryClient;
  newCategories?: CollectionCategory[];
  deletedCategoryId?: number;
  collectibleType: CollectibleType;
}) {
  const listQueryKey = makeListCollectionCategoriesQueryKey({ collectibleType });
  const currentData = queryClient.getQueryData<ListCollectionCategoriesOutput>(listQueryKey);
  if (currentData) {
    const newCategoriesIdsSet = new Set(newCategories?.map((v) => v.id));
    let updatedItems = currentData.items.filter(
      (item) => item.id !== deletedCategoryId && !newCategoriesIdsSet.has(item.id)
    );
    if (newCategories?.length) {
      updatedItems = [...updatedItems, ...newCategories].sort((a, b) => a.name.localeCompare(b.name));
    }
    queryClient.setQueryData(listQueryKey, {
      ...currentData,
      items: updatedItems,
    });
  } else {
    queryClient.invalidateQueries(listQueryKey);
  }
}
