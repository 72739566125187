import { Separator } from '@sportscardinvestor/sci-ui/src/components/ui/separator';
import { useCollectible } from '../useCollectible';
import { useCollectibleFlyoutStoreSelector } from './store';
import FlyoutDateRangeSelector from './FlyoutDateRangeSelector';
import CollectibleStats from './CollectibleStats';
import CollectiblesForSale from './CollectiblesForSale';
import CollectiblesCompletedSales from './CollectiblesCompletedSales';
import NonCustomCollectibleInfo from './NonCustomCollectibleInfo';
import CustomCollectibleInfo from './CustomCollectibleInfo';
import CollectionInfo from './CollectionInfo';
import FlyoutChart from './FlyoutChart';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import { useAggregatedCollectionItemByCollectibleId } from '@/features/collection/useAggregatedCollectionItemsList';

export function CollectibleDetails() {
  const { collectibleId, collectibleType, isCustom } = useCollectibleFlyoutStoreSelector(
    'collectibleId',
    'collectibleType',
    'isCustom'
  );

  const { data: nonCustomCollectible, isLoading: isLoadingCollectible } = useCollectible(
    {
      collectibleId: collectibleId!,
      collectibleType,
    },
    {
      enabled: !isCustom && !!collectibleId,
    }
  );
  const { item: collectibleInCollection, isLoading: isLoadingCustomCollectible } =
    useAggregatedCollectionItemByCollectibleId(
      {
        collectibleId,
        collectibleType,
        isCustom,
      },
      {
        enabled: isCustom,
      }
    );
  const customCollectible = collectibleInCollection?.customCollectible;

  if (isLoadingCollectible || (isCustom && isLoadingCustomCollectible))
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );

  if (!nonCustomCollectible && !customCollectible) {
    return null;
  }

  const deprecated_collectionItemId =
    collectibleInCollection?.purchaseItems?.[0]?.id ?? collectibleInCollection?.saleItems?.[0]?.id;

  return (
    <div className="flex flex-col gap-4 ml-4 mb-4 mt-4 w-full overflow-hidden">
      {!!nonCustomCollectible && <NonCustomCollectibleInfo nonCustomCollectible={nonCustomCollectible} />}
      {!!customCollectible && !!deprecated_collectionItemId && (
        <CustomCollectibleInfo
          customCollectible={customCollectible}
          deprecated_collectionItemId={deprecated_collectionItemId}
        />
      )}
      <div className="flex flex-col gap-4 overflow-y-auto pr-4">
        {!isCustom && (
          <>
            <Separator className="bg-gray-300" />
            <FlyoutChart />
            <FlyoutDateRangeSelector />
            <Separator className="bg-gray-300" />
            <CollectibleStats />
          </>
        )}
        <CollectionInfo />
        {!isCustom && (
          <>
            <Separator className="bg-gray-300" />
            <CollectiblesCompletedSales />
            <Separator className="bg-gray-300" />
            <CollectiblesForSale />
          </>
        )}
      </div>
    </div>
  );
}
