import { useCollectible } from '../useCollectible';
import { useCollectibleFlyoutStoreSelector } from './store';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import DashboardStat from '@/sci-ui-components/stats/DashboardStat/DashboardStat';

export default function CollectibleStats() {
  const { collectibleId, collectibleType, dateRangeInDays } = useCollectibleFlyoutStoreSelector(
    'collectibleId',
    'collectibleType',
    'dateRangeInDays'
  );

  const { data, isLoading } = useCollectible(
    {
      collectibleType,
      collectibleId: collectibleId!,
    },
    {
      enabled: !!collectibleId,
    }
  );

  const statsForDateRange = data?.stats?.[`last${dateRangeInDays}`] ?? null;

  if (isLoading)
    return (
      <div className="flex h-[40] items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );

  if (!statsForDateRange) return <div className="flex items-center justify-center">No sales data.</div>;

  return (
    <div className="flex">
      <DashboardStat
        label={`${dateRangeInDays} Day Avg`}
        value={statsForDateRange.avgPrice ?? null}
        type="price"
        size="medium"
        textClassName="!font-semibold"
        className="flex-1"
      />
      <DashboardStat
        label="Sales Count"
        value={statsForDateRange.totalSalesCount ?? null}
        type="count"
        size="medium"
        textClassName="!font-semibold"
        className="flex-1"
      />
    </div>
  );
}
