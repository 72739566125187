import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleQuery,
  isSportsCardCollectible,
  makeCollectibleDescriptions,
} from '@sportscardinvestor/collectible-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonCollectibleActions, useCommonCollectibleActions } from '../useCommonCollectiblesActions';
import CollectibleImageModal from '@/sci-ui-components/collectibles/CollectibleImageModal/CollectibleImageModal';
import CollectibleText from '@/sci-ui-components/collectibles/CollectibleText/CollectibleText';
import RookieIndicator from '@/sci-ui-components/sport-cards/RookieIndicator/RookieIndicator';
import ManualIndicator from '@/sci-ui-components/collectibles/ManualIndicator/ManualIndicator';
import { ebayAffiliateDisclosure } from '@/constants';
import DashboardStat from '@/sci-ui-components/stats/DashboardStat/DashboardStat';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import { useCommonCollectionActions } from '@/features/collection/useCommonCollectionActions';
import { showEditCustomCollectibleValueDialog } from '@/features/collection/EditCustomCollectibleValueDialog/store';
import ActionButton from '@/sci-ui-components/buttons/ActionButton';

type CustomCardInfoActions = Pick<CommonCollectibleActions, 'forSaleUrl' | 'trackForSaleClick'>;

export default function CustomCollectibleInfo({
  customCollectible,
  deprecated_collectionItemId,
}: {
  customCollectible: CustomCollectible;
  /**
   * TODO: delete this param once custom collectible edit dialog uses mutliverse api
   */
  deprecated_collectionItemId: number;
}) {
  const { collectibleType } = customCollectible;
  const commonActions = useCommonCollectibleActions({
    collectibleId: customCollectible.id,
    collectibleType,
    customCollectible,
    query: customCollectible ? getCollectibleQuery(customCollectible) : null,
  });

  const collectionActions = useCommonCollectionActions();

  const actions: CustomCardInfoActions = {
    forSaleUrl: commonActions.forSaleUrl,
    trackForSaleClick: () => commonActions.forSaleUrl && commonActions.trackForSaleClick,
  };

  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptions(customCollectible);

  return (
    <div className="flex gap-4 mr-4 pr-4 w-full">
      <CollectibleImageModal
        className="w-48"
        url={customCollectible.imageUrl}
        alt={title}
        collectibleType={collectibleType}
      />
      <div className="flex flex-1 flex-col gap-1 justify-between">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <CollectibleText type="title" size="large" className="text-ellipsis">
              {title}
            </CollectibleText>
            <ManualIndicator isManual={true} />
            {isSportsCardCollectible(customCollectible) && <RookieIndicator isRookie={customCollectible.isRookie} />}
          </div>
          <CollectibleText type="description" size="medium">
            {descriptionLine1}
          </CollectibleText>
          {isSportsCardCollectible(customCollectible) && (
            <>
              {!!descriptionLine2 && (
                <span className="text-wrap rounded-sm w-full border text-white bg-gray-primary p-2 leading-4">
                  {descriptionLine2}
                </span>
              )}
              <span className="text-wrap rounded-sm w-full border text-white bg-primary p-2 leading-4">
                {customCollectible.gradeName}
              </span>
            </>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 max-w-[260px]">
            <Button
              asChild
              variant="ghost"
              className="text-2xl text-[#666666] p-0 hover:bg-transparent"
              title={`For Sale \n(${ebayAffiliateDisclosure})`}
              onClick={actions.trackForSaleClick}
            >
              <a href={actions.forSaleUrl || undefined} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faCircleDollar} />
              </a>
            </Button>
            <ActionButton
              onClick={() =>
                collectionActions.showEditCustomCollectionItemDialog({
                  collectibleType,
                  collectionItemId: deprecated_collectionItemId,
                })
              }
            >
              Edit
            </ActionButton>
            <ActionButton
              onClick={() =>
                showEditCustomCollectibleValueDialog({
                  collectibleType,
                  customCollectibleId: customCollectible.id,
                })
              }
            >
              Check Price
            </ActionButton>
          </div>
          <div className="flex flex-col gap-1">
            {!!customCollectible?.priceDate && (
              <span className="text-xs text-[#666666]">{formatDateInTimeZone(customCollectible?.priceDate)}</span>
            )}
            <div className="flex flex-row gap-2 -mb-1">
              {!!customCollectible.price && (
                <DashboardStat
                  value={customCollectible.price}
                  type="price"
                  variant="context"
                  size="medium"
                  textClassName="!font-semibold"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
