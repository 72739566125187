import { Select as AntdSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useAvailableVariationsByCollectible } from '../../variations/useAvailableVariationsByCollectible';
import { setCollectibleId } from './store';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import { CollectibleSportsCard } from '@sportscardinvestor/schemas';
import { useMemo } from 'react';
import { makeSetVariationDisplayName } from '@sportscardinvestor/collectible-helpers';
import clsx from 'clsx';
import { makeFilterOption } from '@/sci-ui-components/forms/SingleSelect/SingleSelect';

export function VariationOptionsSelect({
  collectible,
  className,
}: {
  collectible: CollectibleSportsCard;
  className?: string;
}) {
  const { formatStatValue } = useFormatStatValue();

  const { data: { options: variationOptions } = {} } = useAvailableVariationsByCollectible({
    player_id: collectible.player.id,
    set_id: collectible.set.id,
    grade_id: collectible.grade.id,
  });

  const selectedVariationValue = makeVariationValue({
    variationName: collectible.setVariation.variation.name,
    specificQualifier: collectible.specificQualifier,
    printRun: collectible.setVariation.printRun,
  });

  const variationSelectOptions = useMemo(
    () =>
      variationOptions?.map(
        ({
          print_run,
          specific_qualifier,
          variation_name,
          label,
          cardGrades,
          all_end_avg,
          last_sale_date,
          populationCount,
        }) => ({
          variationValue: makeVariationValue({
            variationName: variation_name,
            specificQualifier: specific_qualifier,
            printRun: print_run,
          }),
          variationName: label,
          cardGrades,
          currentPrice: all_end_avg,
          lastSaleDate: last_sale_date,
          populationCount,
        })
      ) ?? [
        {
          variationValue: selectedVariationValue,
          variationName: makeSetVariationDisplayName({
            printRun: collectible.setVariation.printRun,
            variationName: collectible.setVariation.variation.name,
          }),
          cardGrades: [
            {
              grade_id: collectible.grade.id,
              card_id: collectible.id,
              grade_name: collectible.grade.name,
              grade_order: collectible.grade.order,
            },
          ],
          currentPrice: collectible.stats.all.endAvgPrice,
          lastSaleDate: collectible.stats.lastSaleDate,
          populationCount: collectible.stats.currentPopulationCount,
        },
      ],
    [variationOptions, collectible, selectedVariationValue]
  );

  return (
    <AntdSelect
      showSearch
      placeholder="Card Variation"
      optionFilterProp="children"
      optionLabelProp="label"
      className={clsx(
        className,
        'rounded-sm w-full border text-white bg-gray-primary [&>*]:!cursor-pointer [&_.ant-select-selector]:!h-fit [&_.ant-select-selection-item]:!text-wrap [&_.ant-select-arrow]:!h-full [&_.ant-select-arrow]:!top-[6px] [&_.ant-select-arrow]:!w-[32px] [&_.ant-select-arrow]:!-mr-3 [&_.ant-select-arrow]:!bg-white [&_.ant-select-arrow]:!rounded-r-sm [&_.ant-select-arrow]:!border [&_.ant-select-arrow]:!border-gray-primary [&_.ant-select-selector]:!items-center'
      )}
      bordered={false}
      suffixIcon={<FontAwesomeIcon icon={faCaretDown} className="text-gray-primary h-[30px] w-[30px]" />}
      value={selectedVariationValue}
      filterOption={(input, option) => makeFilterOption(input)(input, option)}
      dropdownStyle={{ pointerEvents: 'auto' }}
      onChange={(_, o) => {
        if (!Array.isArray(o)) {
          const newGrade =
            o.opt.cardGrades.find(({ grade_id }: { grade_id: number }) => grade_id === collectible.grade.id) ??
            o.opt.cardGrades[0];
          setCollectibleId({ collectibleId: newGrade.card_id });
        }
      }}
    >
      {variationSelectOptions.map((opt) => (
        <AntdSelect.Option key={opt.variationValue} value={opt.variationValue} label={opt.variationName} opt={opt}>
          <div className="flex flex-row items-center">
            <div className="flex flex-col mr-auto">
              <span className="text-primary font-normal text-wrap">{opt.variationName}</span>
              {!!opt.populationCount && (
                <span className="text-xs text-white-bg-gray-muted">(pop: {opt.populationCount})</span>
              )}
            </div>
            <div className="flex flex-col items-center w-[65px]">
              <span className="font-bold">
                {!!opt.currentPrice && formatStatValue({ value: opt.currentPrice, type: 'price' })}
              </span>
              <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.lastSaleDate)}</span>
            </div>
          </div>
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}

function makeVariationValue({
  variationName,
  specificQualifier,
  printRun,
}: {
  variationName: string;
  specificQualifier: string | null | undefined;
  printRun: string | null | undefined;
}) {
  return `${variationName}_${specificQualifier ?? ''}_${printRun ?? ''}`;
}
