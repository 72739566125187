import { Select as AntdSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useAvailableBoxTypes } from '../../boxTypes/useAvailableBoxTypes';
import { setCollectibleId } from './store';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import { CollectibleSealedWax } from '@sportscardinvestor/schemas';
import clsx from 'clsx';
import { makeFilterOption } from '@/sci-ui-components/forms/SingleSelect/SingleSelect';

export function BoxTypeOptionsSelect({
  collectible,
  className,
}: {
  collectible: CollectibleSealedWax;
  className?: string;
}) {
  const { formatStatValue } = useFormatStatValue();

  const { data: boxTypes } = useAvailableBoxTypes({
    set_id: collectible.set.id,
  });

  const boxTypeSelectOptions = boxTypes?.options?.map(
    ({ box_type_name, box_type_id, all_end_avg, last_sale_date, sealed_id }) => ({
      boxTypeId: box_type_id,
      boxTypeName: box_type_name,
      allEndAvg: all_end_avg,
      lastSaleDate: last_sale_date,
      sealedId: sealed_id,
    })
  ) || [
    {
      boxTypeId: collectible.boxType.id,
      boxTypeName: collectible.boxType.name,
      allEndAvg: collectible.stats.all.endAvgPrice,
      lastSaleDate: collectible.stats.lastSaleDate,
      sealedId: collectible.id,
    },
  ];

  return (
    <AntdSelect
      showSearch
      placeholder="Box Type"
      optionFilterProp="children"
      optionLabelProp="label"
      className={clsx('rounded-sm w-full border text-white bg-gray-primary [&>*]:!cursor-pointer', className)}
      bordered={false}
      suffixIcon={
        <FontAwesomeIcon
          icon={faCaretDown}
          className="text-gray-primary h-[30px] w-[30px] -mr-3 bg-white rounded-r-sm border border-gray-primary"
        />
      }
      defaultValue={collectible.boxType.id}
      filterOption={(input, option) => makeFilterOption(input)(input, option)}
      dropdownStyle={{ pointerEvents: 'auto' }}
      onChange={(_, o) => {
        if (!Array.isArray(o)) {
          setCollectibleId({
            collectibleId: o.opt.sealedId,
          });
        }
      }}
    >
      {boxTypeSelectOptions.map((opt) => (
        <AntdSelect.Option key={opt.boxTypeId} value={opt.boxTypeId} label={opt.boxTypeName} opt={opt}>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <span className="max-w-[200px] text-primary truncate font-normal mr-auto">{opt.boxTypeName}</span>{' '}
              <span className="font-bold">
                {!!opt.allEndAvg && formatStatValue({ value: opt.allEndAvg, type: 'price' })}
              </span>
              <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.lastSaleDate)}</span>
            </div>
          </div>
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}
