import type { CollectionPurchaseItem, AggregatedCollectionItem } from '@sportscardinvestor/schemas';
import type {
  CollectionItem as OldCollectionItem,
  BaseCollectionItem as OldBaseCollectionItem,
} from '../../../sci-ui-components/types/collectionItem';

export function temp_convertPurchaseItemToOldCollectionItem(
  item: CollectionPurchaseItem,
  {
    collectibleType,
    customPrice,
    collectible,
    customCollectible,
    isCustom,
  }: Pick<
    AggregatedCollectionItem,
    'collectibleType' | 'customPrice' | 'collectible' | 'customCollectible' | 'isCustom'
  >
): OldCollectionItem {
  const collectibleId = isCustom ? customCollectible?.id : collectible?.id;
  if (!collectibleId) {
    throw new Error('Item is neither custom nor featured collectible');
  }
  const lastSaleDate = (isCustom ? customCollectible?.priceDate : collectible?.stats?.lastSaleDate) ?? null;
  const old: OldBaseCollectionItem = {
    id: item.id,
    collectibleId: collectibleId,
    collectibleType,
    createdAt: item.datePurchased,
    note: item.note,
    purchasePricePerItem: item.purchasePricePerItem,
    purchasePriceTotal: item.purchasePriceTotal,
    quantity: item.quantity,
    updatedAt: null,
    customPrice: customPrice?.price,
    collectionCategories: item.collectionCategories.map(({ collectibleType, id, name }) => ({
      collectibleType,
      collectionId: item.id,
      id,
      name,
    })),
    datePurchased: item.datePurchased,
    deletedAt: null,
    lastSale: lastSaleDate,
    // NOTE: this is a purchase item - it is not sold
    dateSold: null,
    soldFeesPerItem: null,
    soldPricePerItem: null,
  };

  return old;
}
