import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '../../../../sci-ui-components/forms/TextField/TextField';
import ModalDialog from '../../../../sci-ui-components/ModalDialog/ModalDialog';
import { SavedSearchCategory } from '../../../../sci-ui-components/types/search';
import useSavedSearchCategoryMutations from '../useSavedSearchCategoryMutations';
import { EditSavedSearchCategoryDialogInfoProps } from './types';

const formId = 'edit-saved-search-category';
const nameFieldId = `${formId}-name`;

type FormValues = {
  name: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
});

export default function EditSavedSearchCategoryDialog({
  isOpen,
  onClose,
  onCreate,
  categoryId,
  initialName,
  collectibleType,
  savedSearchType,
}: EditSavedSearchCategoryDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (createdCategory: SavedSearchCategory) => void;
}) {
  const { create, rename } = useSavedSearchCategoryMutations({
    collectibleType: collectibleType!,
    savedSearchType: savedSearchType,
  });

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const newCategory = categoryId
      ? await rename.mutateAsync({
          id: categoryId,
          name: values.name,
          savedSearchType,
        })
      : await create.mutateAsync({
          name: values.name,
          savedSearchType,
        });
    onCreate?.(newCategory);
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const visible = isOpen && !!collectibleType;

  return (
    <Formik
      initialValues={{
        name: initialName ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <ModalDialog
          open={visible}
          title={`${categoryId ? 'Edit' : 'Create New'} Saved Search Category`}
          okText={categoryId ? 'Save' : 'Create'}
          onCancel={onClose}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FieldLabelBox label="Category name" fieldId={nameFieldId} error={errors.name}>
              <TextField
                id={nameFieldId}
                name="name"
                value={values.name}
                onChange={(value, name) => handleChange({ target: { name, value } })}
              />
            </FieldLabelBox>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}
