import { AggregatedCollectionItem } from '@sportscardinvestor/schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCaretDown, faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@sportscardinvestor/sci-ui/src/components/ui/collapsible';
import { Separator } from '@sportscardinvestor/sci-ui/src/components/ui/separator';
import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { useCollapsibleStateFromStorage } from './helpers';
import { showCollectibleFlyout, useCollectibleFlyoutStoreSelector } from './store';
import { useCommonCollectionActions } from '@/features/collection/useCommonCollectionActions';
import DashboardStat from 'sci-ui-components/stats/DashboardStat/DashboardStat';
import SubSectionHeader from 'sci-ui-components/SubSectionHeader/SubSectionHeader';
import CollectionItemPurchaseDetails from '@/features/collection/CollectionItemPurchaseDetails';
import ActionButton from '@/sci-ui-components/buttons/ActionButton';
import CategoryChipList from '@/sci-ui-components/sport-cards/CategoryChip/CategoryChipList';
import { useAggregatedCollectionItemByCollectibleId } from '@/features/collection/useAggregatedCollectionItemsList';
import { temp_convertPurchaseItemToOldCollectionItem } from '@/features/collection/helpers/temp_convertPurchaseItemToOldCollectionItem';

export default function CollectionInfo() {
  const { collectibleId, collectibleType, isCustom } = useCollectibleFlyoutStoreSelector(
    'collectibleId',
    'collectibleType',
    'isCustom'
  );

  const { item: collectibleInCollection, isLoading } = useAggregatedCollectionItemByCollectibleId({
    collectibleId,
    collectibleType,
    isCustom,
  });

  if (isLoading || !collectibleInCollection?.purchaseItems) {
    return null;
  }

  if (!collectibleInCollection.purchaseItems?.length) {
    return null;
  }

  return (
    <>
      <CollectionStats collectibleInCollection={collectibleInCollection} />
      <Separator className="bg-gray-300" />
      <PurchaseDetails aggregatedCollectionItem={collectibleInCollection} />
    </>
  );
}

function CollectionStats({ collectibleInCollection }: { collectibleInCollection: AggregatedCollectionItem }) {
  const { quantity, collectible, isCustom, netValueTotal, currentValueTotal, totalPriceOfOwnershipTotal } =
    collectibleInCollection;

  const canUseNetValue = isCustom || (collectible?.stats?.all?.endAvgPrice ?? 0) > 0;

  return (
    <div className="flex flex-col gap-4">
      <Separator className="bg-gray-300" />
      <SubSectionHeader className="">Collection Stats</SubSectionHeader>
      <div className="flex flex-row justify-between">
        <DashboardStat
          label="Owned"
          value={quantity}
          type="count"
          size="medium"
          className="flex-1"
          textClassName="!font-semibold"
        />
        <DashboardStat
          label="Net Value"
          value={canUseNetValue ? netValueTotal : 'N/A'}
          type="price"
          size="medium"
          className="flex-1"
          highlightChange
          textClassName="!font-semibold"
        />
        <DashboardStat
          label="Total Value"
          value={currentValueTotal || totalPriceOfOwnershipTotal}
          type="price"
          size="medium"
          className="flex-1"
          textClassName="!font-semibold"
        />
      </div>
    </div>
  );
}

function PurchaseDetails({ aggregatedCollectionItem }: { aggregatedCollectionItem: AggregatedCollectionItem }) {
  const [open, setOpen] = useCollapsibleStateFromStorage('PurchaseDetails');
  const {
    deleteItem,
    showEditCollectionItemPurchaseDetailsDialog,
    markAsSold,
    canConvertCollectionItemGrade,
    showConvertCollectionItemGradeDialog,
  } = useCommonCollectionActions();
  const { purchaseItems = [], collectibleType } = aggregatedCollectionItem;
  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="flex flex-col">
        <CollapsibleTrigger asChild>
          <Button
            variant="ghost"
            size="lg"
            className="pl-0 pr-0 mb-4 -mt-1 font-black uppercase text-[24px] h-[32px] hover:bg-transparent"
          >
            <span className="mr-auto">Purchase Details</span>
            <FontAwesomeIcon icon={open ? faCaretDown : faCaretLeft} />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          {purchaseItems.map((item) => (
            <div key={item.id} className="mb-2">
              <CollectionItemPurchaseDetails {...item} collectibleType={collectibleType} />
              <CategoryChipList categories={item.collectionCategories?.map((cat) => cat.name)} />
              <div className="flex flex-row">
                <div className="flex flex-row mr-auto">
                  <ActionButton
                    onClick={() =>
                      showEditCollectionItemPurchaseDetailsDialog({
                        collectibleType,
                        collectionItemId: item.id,
                      })
                    }
                  >
                    Edit
                  </ActionButton>
                  {canConvertCollectionItemGrade(aggregatedCollectionItem) && (
                    <ActionButton
                      onClick={() =>
                        showConvertCollectionItemGradeDialog({
                          collectibleType,
                          collectionItemId: item.id,
                          onSuccess: ({ collectibleType, newGradeCollectibleId }) =>
                            showCollectibleFlyout({
                              collectibleId: newGradeCollectibleId,
                              collectibleType,
                              isCustom: false,
                            }),
                        })
                      }
                    >
                      Convert Grade
                    </ActionButton>
                  )}
                  <ActionButton
                    onClick={() => {
                      markAsSold({
                        collectionItem: temp_convertPurchaseItemToOldCollectionItem(item, aggregatedCollectionItem),
                      });
                    }}
                  >
                    Mark Sold
                  </ActionButton>
                </div>
                <Button
                  variant="ghost"
                  className="text-danger pr-0 text-xl hover:bg-transparent hover:text-red-800"
                  onClick={() => {
                    deleteItem(item.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              </div>
            </div>
          ))}
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}
