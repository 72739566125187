import { useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '../../../sci-ui-components/forms/TextField/TextField';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { FavoriteCategory } from '../../../sci-ui-components/types/favoriteCategory';
import useFavoritePlayersCategoriesMutations from '../useFavoritePlayersCategoriesMutations';
import { playersCollectibleType } from '../../players/constants';
import { AddOrEditFavoritePlayersCategoryDialogInfoProps } from './types';
import { trackEvent } from '@/features/analytics/trackEvent';

const formId = 'add-favorite-players-category';
const nameFieldId = `${formId}-name`;

type FormValues = {
  name: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
});

const collectibleType = playersCollectibleType;

export default function AddOrEditFavoritePlayersCategoryDialog({
  isOpen,
  onClose,
  onCreate,
  categoryId,
  name: initialName,
}: AddOrEditFavoritePlayersCategoryDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (createdCategory: FavoriteCategory) => void;
}) {
  const { create, update } = useFavoritePlayersCategoriesMutations();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (categoryId) {
      const updatedCategory = await update.mutateAsync({
        categoryId,
        name: values.name,
      });
      onCreate?.(updatedCategory);
    } else {
      const newCategory = await create.mutateAsync({
        categoryName: values.name,
      });
      onCreate?.(newCategory);
    }
    onClose();
    setSubmitting(false);
    resetForm();
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_CATEGORY_ADD_DIALOG_OPENED',
        collectibleType,
      });
    }
  }, [isOpen]);

  return (
    <Formik
      initialValues={{
        name: initialName ?? '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <ModalDialog
          open={isOpen}
          title={categoryId ? 'Edit Favorite Players Category' : 'Add New Favorite Players Category'}
          okText={categoryId ? 'Update' : 'Create'}
          onCancel={onClose}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FieldLabelBox label="Add New Category" fieldId={nameFieldId} error={errors.name}>
              <TextField
                id={nameFieldId}
                name="name"
                value={values.name}
                onChange={(value, name) => handleChange({ target: { value, name } })}
              />
            </FieldLabelBox>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}
