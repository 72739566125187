import { useCallback, useMemo } from 'react';
import { isCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import { getCollectionCollectible } from '@sportscardinvestor/collection-helpers';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations_deprecated from '../deprecated_useCollectionItemMutations';
import { useCollectionItem_deprecated } from '../deprecated_useListCollectionItems';
import { useEditCollectionItemPurchaseDetailsDialogStore } from './store';
import {
  EditCollectionItemPurchaseDetailsForm,
  getEditCollectionItemPurchaseDetailsFormInitialValues,
} from './EditCollectionItemPurchaseDetailsForm';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import { trackEvent } from '@/features/analytics/trackEvent';

export const EditCollectionItemPurchaseDetailsDialog = withErrorBoundary(
  function EditCollectionItemPurchaseDetailsDialog() {
    const { collectionItemId, collectibleType, close } = useEditCollectionItemPurchaseDetailsDialogStore();
    const { collectionItem } = useCollectionItem_deprecated({
      collectibleType,
      collectionItemId,
    });
    const { updateCollectionItem } = useCollectionItemMutations_deprecated();
    const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();
    const handleCancel = useCallback(() => {
      trackEvent({
        eventName: 'COLLECTION_EDIT_PURCHASE_DETAILS_CANCELLED',
        collectibleType,
        collectionItemId,
      });
      close();
    }, [close, collectibleType, collectionItemId]);

    const initialValues = useMemo(
      () =>
        getEditCollectionItemPurchaseDetailsFormInitialValues({
          collectionItem,
          convertPriceFromUSD,
        }),
      [collectionItem, convertPriceFromUSD]
    );

    return (
      <ModalDialog
        open={!!collectionItem}
        title="Edit Purchase Details"
        onCancel={handleCancel}
        footer={null}
        variant="primary"
        width="fit-content"
        withHeaderUnderline
      >
        {!!collectionItem && (
          <EditCollectionItemPurchaseDetailsForm
            initialValues={initialValues}
            collectible={getCollectionCollectible(collectionItem)}
            onSubmit={({
              categoryId,
              gradingPricePerItemInDisplayCurrency,
              notes,
              purchaseDate,
              purchasePricePerItemInDisplayCurrency,
              quantity,
            }) =>
              updateCollectionItem.mutate(
                {
                  collectibleType,
                  category: {
                    collectionItemId: collectionItem.id,
                    collectionCategoryId: categoryId,
                  },
                  note: {
                    collectionItemId: collectionItem.id,
                    note: notes ?? '',
                  },
                  purchaseDetails: {
                    collectibleType,
                    collectionItemId: collectionItem.id,
                    isCustom: isCustomCollectible(getCollectionCollectible(collectionItem)),
                    purchaseDate,
                    purchasePricePerItem: convertPriceToUSD({ value: purchasePricePerItemInDisplayCurrency }) ?? 0,
                    quantity,
                    gradingPricePerItem: convertPriceToUSD({ value: gradingPricePerItemInDisplayCurrency }),
                  },
                },
                {
                  onSuccess: () => {
                    trackEvent({
                      eventName: 'COLLECTION_EDIT_PURCHASE_DETAILS_COMPLETED',
                      collectibleType,
                    });
                    close();
                  },
                  onError: () => {
                    trackEvent({
                      eventName: 'COLLECTION_EDIT_PURCHASE_DETAILS_FAILED',
                      collectibleType,
                    });
                  },
                }
              )
            }
            onCancel={handleCancel}
            isLoading={updateCollectionItem.isLoading}
            collectibleType={collectibleType}
          />
        )}
      </ModalDialog>
    );
  }
);
