import clsx from 'clsx';
import Button, { Size } from '../Button/Button';
import { ForSaleItemType } from '../../../features/sales/useForSaleList';
import classes from './ListingTypeToggle.module.scss';

export interface ListingTypeToggleProps {
  forSaleItemType: ForSaleItemType;
  onForSaleItemTypeChange: (forSaleItemType: ForSaleItemType) => void;
  containerClassName?: string;
  buttonClassName?: string;
  buttonSize?: Size;
}

export default function ListingTypeToggle({
  forSaleItemType,
  onForSaleItemTypeChange,
  containerClassName,
  buttonClassName,
  buttonSize = 'large',
}: ListingTypeToggleProps) {
  return (
    <div className={clsx(classes.forSaleItemTypeButtonsContainer, containerClassName)}>
      <Button
        className={clsx(
          {
            [classes.enabled]: forSaleItemType === 'Auctions',
            [classes.disabled]: forSaleItemType !== 'Auctions',
          },
          buttonClassName
        )}
        onClick={() => onForSaleItemTypeChange('Auctions')}
        type="default"
        size={buttonSize}
      >
        AUCTIONS
      </Button>
      <Button
        className={clsx(
          {
            [classes.enabled]: forSaleItemType === 'FixedPrice',
            [classes.disabled]: forSaleItemType !== 'FixedPrice',
          },
          buttonClassName
        )}
        onClick={() => onForSaleItemTypeChange('FixedPrice')}
        type="default"
        size={buttonSize}
      >
        FIXED PRICE
      </Button>
    </div>
  );
}
