import { useCallback, useMemo } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import { useCustomCollectible_deprecated } from '../deprecated_useListCollectionItems';
import useCollectionItemMutations_deprecated from '../deprecated_useCollectionItemMutations';
import { useEditCustomCollectibleValueDialogStore } from './store';
import {
  EditCustomCollectibleValueCallbacks,
  EditCustomCollectibleValueForm,
  getEditCustomCollectibleValueFormInitialValues,
  OnEditCustomCollectibleValueFormSubmitFn,
} from './EditCustomCollectibleValueForm';
import ModalDialog from '@/sci-ui-components/ModalDialog/ModalDialog';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { trackEvent } from '@/features/analytics/trackEvent';

export const EditCustomCollectibleValueDialog = withErrorBoundary(function EditCustomCollectibleValueDialog() {
  const { collectibleType, customCollectibleId, close } = useEditCustomCollectibleValueDialogStore();
  const { customCollectible } = useCustomCollectible_deprecated(
    {
      collectibleId: customCollectibleId!,
      collectibleType,
    },
    {
      enabled: !!customCollectibleId,
    }
  );
  const { updateCollectionItem } = useCollectionItemMutations_deprecated();
  const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();

  const currentImageUrl = customCollectible?.imageUrl ?? null;
  const handleSaveValue = useCallback<OnEditCustomCollectibleValueFormSubmitFn>(
    ({ currentPriceInDisplayCurency, customQuery, imageUrl }) => {
      if (customCollectibleId) {
        updateCollectionItem.mutateAsync(
          {
            customCollectible: {
              collectibleType: collectibleType,
              collectibleId: customCollectibleId,
              customQuery: customQuery?.trim() || null,
              currentValue: convertPriceToUSD({ value: currentPriceInDisplayCurency }),
              ...(imageUrl !== currentImageUrl
                ? {
                    imageUrl,
                  }
                : {}),
            },
            collectibleType: collectibleType,
          },
          {
            onSuccess: () => {
              trackEvent({
                eventName: 'COLLECTION_CHECK_PRICE_COMPLETED',
                collectibleType,
              });
              close();
            },
            onError: () => {
              trackEvent({
                eventName: 'COLLECTION_CHECK_PRICE_FAILED',
                collectibleType,
              });
            },
          }
        );
      }
    },
    [customCollectibleId, collectibleType, convertPriceToUSD, updateCollectionItem, close, currentImageUrl]
  );
  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: 'COLLECTION_CHECK_PRICE_CANCELLED',
      collectibleType,
    });
    close();
  }, [close, collectibleType]);

  const initialValues = useMemo(
    () =>
      getEditCustomCollectibleValueFormInitialValues({
        customCollectible,
        convertPriceFromUSD,
      }),
    [customCollectible, convertPriceFromUSD]
  );

  const checkValueCallbacks = useMemo<EditCustomCollectibleValueCallbacks>(
    () => ({
      onCustomQueryGenerated: () =>
        trackEvent({
          eventName: 'COLLECTION_CHECK_PRICE_GENERATE_QUERY',
          collectibleType,
        }),
      onCustomQueryUpdated: () =>
        trackEvent({
          eventName: 'COLLECTION_CHECK_PRICE_QUERY_UPDATED',
          collectibleType,
        }),
      onValueUsed: () =>
        trackEvent({
          eventName: 'COLLECTION_CHECK_PRICE_USE_PRICE',
          collectibleType,
        }),
      onPhotoUsed: () =>
        trackEvent({
          eventName: 'COLLECTION_CHECK_PRICE_USE_PHOTO',
          collectibleType,
        }),
      onPhotoSelected: () =>
        trackEvent({
          eventName: 'COLLECTION_CHECK_PRICE_UPDATE_IMAGE',
          collectibleType,
        }),
    }),
    [collectibleType]
  );

  return (
    <ModalDialog
      open={!!customCollectibleId}
      title={'Update Manual Card Value'}
      footer={null}
      onCancel={handleCancel}
      width="fit-content"
      withHeaderUnderline
      wrapClassName="twp"
    >
      {!!customCollectible && (
        <EditCustomCollectibleValueForm
          customCollectible={customCollectible}
          isLoading={updateCollectionItem.isLoading}
          onCancel={handleCancel}
          onSubmit={handleSaveValue}
          initialValues={initialValues}
          {...checkValueCallbacks}
        />
      )}
    </ModalDialog>
  );
});
