import { useCallback } from 'react';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations_deprecated from '../deprecated_useCollectionItemMutations';
import { useCollectionItem_deprecated } from '../deprecated_useListCollectionItems';
import { useConvertCollectionItemGradeDialogStore } from './store';
import { ConvertCollectionItemGradeForm } from './ConvertCollectionItemGradeForm';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import { trackEvent } from '@/features/analytics/trackEvent';

const formId = 'ConvertCollectionItemGradeForm';

export const ConvertCollectionItemGradeDialog = withErrorBoundary(function ConvertCollectionItemGradeDialog() {
  const { collectionItemId, collectibleType, close, onSuccess } = useConvertCollectionItemGradeDialogStore();
  const { collectionItem } = useCollectionItem_deprecated({
    collectibleType,
    collectionItemId,
  });
  const { convertCollectionItemGrade } = useCollectionItemMutations_deprecated();
  const { convertPriceToUSD } = useCurrencyFormatter();
  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: 'COLLECTION_CONVERT_GRADE_CANCELLED',
      collectibleType,
      collectionItemId,
    });
    close();
  }, [close, collectibleType, collectionItemId]);

  return (
    <ModalDialog
      open={!!collectionItem}
      title="Convert Grade"
      onCancel={handleCancel}
      footer={null}
      variant="primary"
      width={568}
      withHeaderUnderline
    >
      {!!collectionItem && (
        <ConvertCollectionItemGradeForm
          formId={formId}
          collectionItem={collectionItem}
          onSubmit={({ gradingPricePerCardInDisplayCurrency, newGradeCollectibleId, quantity }, collectionItemId) =>
            convertCollectionItemGrade.mutate(
              {
                cards: {
                  [newGradeCollectibleId]: quantity,
                },
                collectionItemId,
                gradingPricePerCard: convertPriceToUSD({ value: gradingPricePerCardInDisplayCurrency }) ?? 0,
              },
              {
                onSuccess: () => {
                  close();
                  trackEvent({
                    eventName: 'COLLECTION_CONVERT_GRADE_COMPLETED',
                    collectibleType,
                    collectionItemId,
                  });
                  onSuccess?.({
                    collectibleType,
                    newGradeCollectibleId,
                  });
                },
                onError: () => {
                  trackEvent({
                    eventName: 'COLLECTION_CONVERT_GRADE_FAILED',
                    collectibleType,
                    collectionItemId,
                  });
                },
              }
            )
          }
          onCancel={handleCancel}
          isLoading={convertCollectionItemGrade.isLoading}
        />
      )}
    </ModalDialog>
  );
});
