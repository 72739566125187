import { CustomCollectibleSealedWax, CustomCollectibleSportsCard } from '@sportscardinvestor/schemas';
import { EditCustomCollectionItemFormPayload } from '../../EditCustomCollectionItemDialog/forms/EditCustomCollectionItemForm';
import { EditSealedCustomCollectionItemFormPayload } from '../../EditCustomCollectionItemDialog/forms/EditSealedCustomCollectionItemForm';
import { EditSportsCardCustomCollectionItemFormPayload } from '../../EditCustomCollectionItemDialog/forms/EditSportsCardCustomCollectionItemForm';
import { EditCustomCollectibleValueFormPayload } from '../../EditCustomCollectibleValueDialog/EditCustomCollectibleValueForm';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export function makeTempCustomCollectibleFromValues({
  customCollectibleInfo,
  customCollectibleValueInfo,
  convertPriceToUSD,
}: {
  customCollectibleInfo: EditCustomCollectionItemFormPayload;
  customCollectibleValueInfo: EditCustomCollectibleValueFormPayload | null;
  convertPriceToUSD: ConvertPriceFn;
}): CustomCollectibleSealedWax | CustomCollectibleSportsCard {
  const valueInfo = makeValueInfo({
    customCollectibleValueInfo,
    convertPriceToUSD,
  });
  switch (customCollectibleInfo.collectibleType) {
    case 'sealed-wax-card':
      return makeTempSealedCustomCollectibleFromEditFormValues({ customCollectibleInfo, valueInfo });
    default:
      return makeTempSportsCardCustomCollectibleFromEditFormValues({ customCollectibleInfo, valueInfo });
  }
}

interface ValueInfo {
  price: number | null;
  priceDate: string | null;
  customQuery: string | null;
  imageUrl: string | null;
}

function makeValueInfo({
  customCollectibleValueInfo,
  convertPriceToUSD,
}: {
  customCollectibleValueInfo: EditCustomCollectibleValueFormPayload | null;
  convertPriceToUSD: ConvertPriceFn;
}): ValueInfo {
  const { customQuery, currentPriceInDisplayCurency, imageUrl } = customCollectibleValueInfo ?? {};
  const price =
    convertPriceToUSD({
      value: currentPriceInDisplayCurency,
    }) ?? null;
  return {
    price,
    priceDate: price ? new Date().toISOString() : null,
    customQuery: customQuery ?? null,
    imageUrl: imageUrl ?? null,
  };
}

function makeTempSealedCustomCollectibleFromEditFormValues({
  customCollectibleInfo,
  valueInfo,
}: {
  customCollectibleInfo: EditSealedCustomCollectionItemFormPayload;
  valueInfo: ValueInfo;
}): CustomCollectibleSealedWax {
  const { imageUrl, setId, setName, setYear, sportName, boxTypeName } = customCollectibleInfo;
  const createdAt = new Date().toISOString();
  const sport = {
    id: -1,
    name: sportName ?? '',
    query: null,
  } as const;
  return {
    collectibleType: 'sealed-wax-card',
    createdAt,
    id: -1,
    isCustom: true,
    sportName,
    boxTypeName,
    query: '',
    set: setId
      ? {
          collectibleType: 'sealed-wax-card',
          id: setId,
          name: setName,
          query: null,
          sport,
          year: setYear,
        }
      : null,
    setName,
    setYear: setYear ? Number(setYear) : null,
    updatedAt: createdAt,
    ...valueInfo,
    imageUrl: valueInfo.imageUrl ?? imageUrl,
  };
}

function makeTempSportsCardCustomCollectibleFromEditFormValues({
  customCollectibleInfo,
  valueInfo,
}: {
  customCollectibleInfo: EditSportsCardCustomCollectionItemFormPayload;
  valueInfo: ValueInfo;
}): CustomCollectibleSportsCard {
  const {
    cardNumber,
    gradeName,
    imageUrl,
    playerId,
    playerName,
    setId,
    setName,
    setYear,
    specificQualifier,
    sportName,
    variationName,
  } = customCollectibleInfo;
  const createdAt = new Date().toISOString();
  const sport = {
    id: -1,
    name: sportName ?? '',
    query: null,
  } as const;
  return {
    cardNumber,
    collectibleType: 'sports-card',
    createdAt,
    gradeName,
    id: -1,
    isCustom: true,
    isRookie: false,
    player: playerId
      ? {
          id: playerId,
          name: playerName ?? '',
          query: null,
          sport,
          rookieYears: [],
        }
      : null,
    sportName,
    playerName,
    query: '',
    set: setId
      ? {
          collectibleType: 'sports-card',
          id: setId,
          name: setName,
          query: null,
          sport,
          year: setYear,
        }
      : null,
    setName,
    setYear: setYear ? Number(setYear) : null,
    specificQualifier,
    updatedAt: createdAt,
    variationName,
    ...valueInfo,
    imageUrl: valueInfo.imageUrl ?? imageUrl,
  };
}
