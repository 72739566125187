import React, { useMemo } from 'react';
import { parseISO } from 'date-fns';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { makeCollectibleDescription } from '../../../sci-ui-components/utils/collectibleDescription';
import TimeLineChart, { LineConfig } from '../../../sci-ui-components/charts/TimeLineChart/TimeLineChart';
import { ChartColors } from '../../../sci-ui-components/styles/chartColors';
import { createTrendLineEquation } from '../../../sci-ui-components/charts/utils/trendLine';
import formatStatValue from '../../../sci-ui-components/utils/formatStatValue';
import DaysFilter from '../../../sci-ui-components/forms/DaysFilter/DaysFilter';
import DateRangeSelector from '../../../sci-ui-components/DateRangeSelector/DateRangeSelector';
import TileContainer from '../../../sci-ui-components/TileContainer/TileContainer';
import useDateRangeState from '../../../hooks/useDateRangeState';
import { useCollectibleOld } from '../../collectibles/useCollectibleOld';
import { useAlternateGradesForCollectible } from '../../grades/useAvailableGrades';
import useChartSettings from '../useChartSettings';
import usePopulationCountChartData from './usePopulationCountChartData';
import getSummaryStats from './utils/getSummaryStats';
import getExtendedChartPoints, { ExtendedPopulationCountChartPoint } from './utils/getExtendedChartPoints';
import Legend from './Legend';
import { PopulationCountChartDialogProps } from './types';

import classes from './PopulationCountChartDialog.module.scss';
import { IsoDateRange } from 'sci-ui-components/utils/date';

export default function PopulationCountChartDialog({
  collectibleId,
  collectibleType = 'sports-card',
  isOpen,
  onClose,
  onCollectibleChange,
}: PopulationCountChartDialogProps & {
  isOpen: boolean;
  onClose: (hasGoneToCharts: boolean) => void;
  onCollectibleChange: (collectibleId: number) => void;
}) {
  const isEmpty = !collectibleId || !collectibleType;
  const { data: collectible = null } = useCollectibleOld({ id: collectibleId, collectibleType });
  const { fullDescription } = makeCollectibleDescription(collectible);
  const { alternateGrades = [] } = useAlternateGradesForCollectible({
    oldCollectible: collectible,
  });
  const [dateRange, setDateRange] = useDateRangeState();
  const { data: popCountchartData = [], isLoading } = usePopulationCountChartData({
    dateRange,
    collectibleId: collectibleId!,
    collectibleType,
  });
  const chartedDateRange = useMemo<IsoDateRange | null>(() => {
    if (!popCountchartData?.length) {
      return null;
    }
    return [popCountchartData[0].date, popCountchartData[popCountchartData.length - 1].date];
  }, [popCountchartData]);
  const { chartSettings } = useChartSettings({ dateRange: chartedDateRange });
  const summaryStats = useMemo(() => getSummaryStats(popCountchartData), [popCountchartData]);
  const extendedChartPoints = useMemo(
    () => getExtendedChartPoints(popCountchartData, { groupBy: chartSettings.groupBy }),
    [popCountchartData, chartSettings.groupBy]
  );
  const lines = useMemo<LineConfig<ExtendedPopulationCountChartPoint, string>[]>(() => {
    const getXValue = (item: ExtendedPopulationCountChartPoint) => parseISO(item.date).valueOf();
    const trendLineEquation = createTrendLineEquation<ExtendedPopulationCountChartPoint>({
      data: extendedChartPoints,
      getYValue: (item) => item.count,
      getXValue,
    });
    return [
      {
        color: ChartColors.L1,
        dataKey: 'count',
        key: 'count',
        label: fullDescription,
        strokeWidth: 3,
        renderStatsInTooltip,
      },
      {
        dataKey: (item) => trendLineEquation(getXValue(item)),
        label: `${fullDescription} Trend`,
        color: ChartColors.L1,
        key: 'count_trend',
        strokeWidth: 3,
        renderStatsInTooltip,
        isTrend: true,
      },
    ];
  }, [extendedChartPoints, fullDescription]);

  const header = (
    <div className={classes.chartHeader}>
      <DateRangeSelector onChange={setDateRange} startDate={dateRange[0]} endDate={dateRange[1]} />
      <DaysFilter dropdownOnBreakpoints={['md', 'sm', 'xs']} onDateChange={setDateRange} dateRange={dateRange} />
    </div>
  );

  const visible = isOpen && !isEmpty;

  return (
    <ModalDialog
      open={visible}
      title="Population Growth"
      titleAlign="center"
      footer={null}
      width="100%"
      onCancel={() => onClose(false)}
      dense
      centered
    >
      <TileContainer className={classes.chartWrapper}>
        <TimeLineChart
          data={extendedChartPoints}
          lines={lines}
          isLoading={isLoading}
          height={400}
          chartHeader={header}
          formatYAxisValue={(value) =>
            formatStatValue({
              value,
              type: 'count',
            })
          }
          xAxisDataKey="date"
          connectNulls
          withDots
          emptyMessage="No data found"
          hideNonTrendLines={false}
          hideTrendLines={true}
          yAxisStartFromMin={true}
          yAxisAllowDecimals={false}
        />
      </TileContainer>
      {!!collectibleId && (
        <Legend
          collectibleId={collectibleId}
          collectible={collectible}
          collectibleType={collectibleType}
          isLoading={isLoading}
          summaryStats={summaryStats}
          alternateGrades={alternateGrades}
          onGradeChange={({ collectibleId }) => onCollectibleChange(collectibleId)}
        />
      )}
    </ModalDialog>
  );
}

function renderStatsInTooltip(item: ExtendedPopulationCountChartPoint) {
  if (!item) {
    return null;
  }
  return (
    <>
      <span className={classes.tooltipStat1}>
        {'Count: '}
        {formatStatValue({
          value: item.count,
          type: 'count',
        })}
      </span>
      <span className={classes.tooltipStat2}>
        {'Growth: '}
        {formatStatValue({
          value: item.growthNumber,
          type: 'count',
        })}
        {` (${formatStatValue({
          value: item.growthPercentage,
          type: 'percentage',
        })})`}
      </span>
    </>
  );
}
