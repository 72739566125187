import { MmApiInput, MmApiOutput, MmApiQueryOptions, mmApiClient, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

type UseAvailableVariationsByCollectibleInput =
  MmApiInput['private']['collectibles']['filters']['availableVariationsForCard'];
type UseAvailableVariationsByCollectibleOutput =
  MmApiOutput['private']['collectibles']['filters']['availableVariationsForCard'];
export const useAvailableVariationsByCollectibleKeyPrefix =
  'private.collectibles.filters.availableVariationsForCard' as const;
type UseAvailableVariationsByCollectibleKey = [
  typeof useAvailableVariationsByCollectibleKeyPrefix,
  UseAvailableVariationsByCollectibleInput
];

export function useAvailableVariationsByCollectible(
  input: UseAvailableVariationsByCollectibleInput,
  options?: MmApiQueryOptions<UseAvailableVariationsByCollectibleOutput, UseAvailableVariationsByCollectibleKey>
) {
  const key: UseAvailableVariationsByCollectibleKey = [useAvailableVariationsByCollectibleKeyPrefix, input];
  const result = useAuthenticatedMMAPIQuery(
    key,
    () => mmApiClient.private.collectibles.filters.availableVariationsForCard.query(input),
    options
  );

  return result;
}
