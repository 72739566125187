import { useCallback } from 'react';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { useManageCollectionCategoriesDialogStore } from './store';
import { ManageCollectionCategoriesForm } from './ManageCollectionCategoriesForm';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import { trackEvent } from '@/features/analytics/trackEvent';
import { OnManageCollectionCategoriesSuccessFn } from './types';

const formId = 'ConvertCollectionItemGradeForm';

export const ManageCollectionCategoriesDialog = withErrorBoundary(function ManageCollectionCategoriesDialog() {
  const { collectibleType, close, isOpen, callback } = useManageCollectionCategoriesDialogStore();
  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: 'COLLECTION_CATEGORY_EDIT_CANCELLED',
      collectibleType,
    });
    close();
    callback?.({ categoryId: null, collectibleType });
  }, [close, collectibleType, callback]);

  const handleSubmit = useCallback<OnManageCollectionCategoriesSuccessFn>(
    (payload) => {
      callback?.(payload);
      close();
    },
    [close, callback]
  );

  return (
    <ModalDialog
      open={isOpen}
      title="Edit Categories"
      onCancel={handleCancel}
      footer={null}
      variant="primary"
      width={568}
      withHeaderUnderline
    >
      {!!callback && (
        <ManageCollectionCategoriesForm formId={formId} collectibleType={collectibleType} onSubmit={handleSubmit} />
      )}
    </ModalDialog>
  );
});
