import { useState } from 'react';
import clsx from 'clsx';
import { CollectibleType, CollectionCategory } from '@sportscardinvestor/schemas';
import useCollectionCategories from '../useCollectionCategories';
import useCollectionCategoryMutations from '../useCollectionCategoryMutations';
import { OnManageCollectionCategoriesSuccessFn } from './types';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import TextField from '@/sci-ui-components/forms/TextField/TextField';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { EditFormButtons } from '@/sci-ui-components/forms/EditForm';
import { IconButton } from '@sportscardinvestor/sci-ui/components/buttons/icon-button';
import { faCheck, faXmark, faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';

export function ManageCollectionCategoriesForm({
  onSubmit,
  onCancel,
  formId = 'manage-collection-categories-form',
  collectibleType,
  className,
}: {
  onSubmit: OnManageCollectionCategoriesSuccessFn;
  onCancel?: () => void;
  formId?: string;
  collectibleType: CollectibleType;
  className?: string;
}) {
  const { items, isLoading } = useCollectionCategories({
    collectibleType,
  });
  return (
    <div className={clsx('twp flex flex-col', className)}>
      <ul className="flex flex-col">
        {!!isLoading && !items.length && (
          <ListItem>
            <LoadingSpinner block size="default" className="mx-auto" />
          </ListItem>
        )}
        {items.map((category) => (
          <ListItem key={category.id}>
            <CategoryItem className="grow shrink" category={category} onSelect={onSubmit} />
          </ListItem>
        ))}
        <ListItem isLast>
          <NewCategoryForm className="mt-4 grow shrink" collectibleType={collectibleType} />
        </ListItem>
      </ul>
      {!!onCancel && <EditFormButtons onCancel={onCancel} isLoading={isLoading} formId={formId} noSubmit />}
    </div>
  );
}

function ListItem({ children, isLast }: { children: React.ReactNode; isLast?: boolean }) {
  return (
    <li
      className={clsx('flex flex-row items-center', {
        'border-b border-b-lightBg-lightGrey pb-2 mb-2': !isLast,
      })}
    >
      {children}
    </li>
  );
}

function CategoryItem({
  category,
  className,
  onSelect,
}: {
  category: CollectionCategory;
  className?: string;
  onSelect: OnManageCollectionCategoriesSuccessFn;
}) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { removeWithConfirmation, editingCategoryIds } = useCollectionCategoryMutations();

  if (isInEditMode) {
    return <EditCategoryForm category={category} className={className} onCancel={() => setIsInEditMode(false)} />;
  }

  const isEditing = editingCategoryIds.has(category.id);

  return (
    <div className={clsx('flex flex-row items-center gap-4', className)}>
      <Button
        className="my-[1px] grow shrink border-transparent justify-start text-start"
        variant="ghost"
        shape="rounded"
        size="medium"
        align="stretch-start"
        onPress={() =>
          onSelect({
            categoryId: category.id,
            collectibleType: category.collectibleType,
          })
        }
      >
        {category.name}
      </Button>
      <IconButton
        className="shrink-0 grow-0"
        faIcon={faPencil}
        title="Edit"
        variant="default"
        shape="round"
        size="medium"
        onPress={() => setIsInEditMode(true)}
        disabled={isEditing}
      />
      <IconButton
        className="shrink-0 grow-0"
        faIcon={faTrash}
        title="Delete"
        variant="danger"
        shape="round"
        size="medium"
        disabled={isEditing}
        onPress={() =>
          removeWithConfirmation({
            collectibleType: category.collectibleType,
            id: category.id,
          })
        }
      />
    </div>
  );
}

function EditCategoryForm({
  className,
  category,
  onCancel,
}: {
  className?: string;
  category: CollectionCategory;
  onCancel: () => void;
}) {
  const { update } = useCollectionCategoryMutations();
  const form = useForm<NewCategoryFormPayload>({
    values: {
      name: category.name,
    },
    mode: 'onSubmit',
  });
  const handleSubmit = form.handleSubmit((payload: NewCategoryFormPayload) =>
    update.mutate(
      {
        id: category.id,
        name: payload.name,
        collectibleType: category.collectibleType,
      },
      {
        onSuccess: () => {
          form.reset();
          onCancel();
        },
      }
    )
  );

  return (
    <form className={clsx('flex flex-row items-center gap-4', className)} onSubmit={handleSubmit}>
      <Controller name="name" control={form.control} render={({ field }) => <TextField {...field} id="edit-name" />} />
      <IconButton
        title="Save"
        className="shrink-0 grow-0"
        type="submit"
        variant="primary"
        size="medium"
        shape="round"
        disabled={update.isLoading}
        faIcon={faCheck}
      />
      <IconButton
        title="Cancel"
        className="shrink-0 grow-0"
        onPress={onCancel}
        variant="default"
        size="medium"
        shape="round"
        disabled={update.isLoading}
        faIcon={faXmark}
      />
    </form>
  );
}

interface NewCategoryFormPayload {
  name: string;
}

const newCategoryDefaultValues: NewCategoryFormPayload = {
  name: '',
};

function NewCategoryForm({ className, collectibleType }: { className?: string; collectibleType: CollectibleType }) {
  const { create } = useCollectionCategoryMutations();
  const form = useForm<NewCategoryFormPayload>({
    defaultValues: newCategoryDefaultValues,
    mode: 'onSubmit',
  });
  const handleSubmit = form.handleSubmit((payload: NewCategoryFormPayload) =>
    create.mutate(
      {
        name: payload.name,
        collectibleType,
      },
      {
        onSuccess: () => {
          form.reset(newCategoryDefaultValues);
        },
      }
    )
  );

  return (
    <form className={clsx('flex flex-row items-center gap-4', className)} onSubmit={handleSubmit}>
      <Controller name="name" control={form.control} render={({ field }) => <TextField {...field} id="new-name" />} />
      <Button
        className="shrink-0 grow-0 w-[5.5rem]"
        type="submit"
        variant="primary"
        size="medium"
        shape="round"
        disabled={create.isLoading}
      >
        Add
      </Button>
    </form>
  );
}
