import { ReactNode, useCallback, useEffect, useState } from 'react';
import classes from './Swim.module.scss';

export function Maybe0401SwimDiv(props: { children: ReactNode; className?: string }) {
  const isSwim = use0401Swim();
  if (isSwim) {
    return <Swim {...props} />;
  }
  return <div {...props} />;
}

function Swim({ className, children }: { className?: string; children: ReactNode }) {
  const handleEvent = useCallback((e: React.MouseEvent<HTMLElement>) => {
    try {
      const target = e.relatedTarget as HTMLElement;
      if (target?.style) {
        target.classList.add(classes.delay);
        target.style.transform = [
          `translate(${Math.random() * 50 - 25}%, ${Math.random() * 50 - 25}%)`,
          `rotate(${Math.random() * 120 - 60}deg)`,
          `scale(${Math.random() * 0.4 + 0.8})`,
        ].join(' ');
        setTimeout(() => {
          try {
            if (target?.style) {
              target.style.transform = '';
            }
          } catch (_err) {
            // do nothing
          }
        }, 1000 * 15);
      }
    } catch (_err) {
      // do nothing
    }
  }, []);

  return (
    <div className={className} onMouseOver={handleEvent}>
      {children}
    </div>
  );
}

function use0401Swim() {
  const [isSwim, setIsSwim] = useState(false);
  useEffect(() => {
    try {
      if (window?.location?.search?.includes('swim0401=1')) {
        sessionStorage.setItem('swim0401', '1');
      } else if (window?.location?.search?.includes('swim0401=0')) {
        sessionStorage.removeItem('swim0401');
      }
      if (sessionStorage.getItem('swim0401') === '1') {
        setIsSwim(true);
        console.warn('time to swim');
      } else {
        setIsSwim(false);
      }
    } catch (_err) {
      // do nothing
    }
  }, [setIsSwim]);

  return isSwim;
}
