import { useMemo } from 'react';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { Collectible } from '../../../sci-ui-components/types/collectible';
import Table from '../../../sci-ui-components/Table/Table';
import DefaultTitle from '../../../sci-ui-components/Table/presets/DefaultTitle';
import CollectibleTableCell from '../../../sci-ui-components/collectibles/CollectibleTableCell/CollectibleTableCell';
import createStatColumn from '../../../sci-ui-components/Table/presets/StatColumn';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import TextCell from '../../../sci-ui-components/Table/presets/TextCell';
import CollectibleActionsRow from '../../../sci-ui-components/collectibles/CollectibleActionsRow/CollectibleActionsRow';
import createIndicatorColumn from '../../../sci-ui-components/Table/presets/IndicatorColumn';
import { AlternateGrade } from '../../../sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';
import { useBreakpoint } from '../../../sci-ui-components/hooks/useBreakpoint';
import { collectibleNames, CollectibleType } from '../../collectibles/constants';
import { useCommonCollectibleActions } from '../../collectibles/useCommonCollectiblesActions';
import { PopulationCountSummaryStats } from './utils/getSummaryStats';
import useFormatStatValue from 'hooks/useFormatStatValue';

export interface LegendProps {
  className?: string;
  isLoading: boolean;
  collectible: Collectible | null;
  summaryStats: PopulationCountSummaryStats | null;
  collectibleType: CollectibleType;
  collectibleId: number;
  alternateGrades?: AlternateGrade[] | null;
  onGradeChange: (alternateGrade: AlternateGrade) => void;
}

type PopCountCollectible = Collectible & {
  serialNumber?: string | null;
};

interface Item {
  collectibleId: number;
  collectible: PopCountCollectible | null;
  summaryStats: PopulationCountSummaryStats | null;
}

const statWidth = 'minmax(92px, auto)';

const PSA_BASE_URL = 'https://www.psacard.com/cert';

export default function Legend({
  className,
  collectible,
  summaryStats,
  isLoading,
  collectibleType,
  collectibleId,
  alternateGrades,
  onGradeChange,
}: LegendProps) {
  const { formatStatValue } = useFormatStatValue();
  const actions = useCommonCollectibleActions({ collectibleType, collectibleId, query: collectible?.query });
  const items = useMemo<Item[]>(
    () => [
      {
        collectibleId,
        summaryStats,
        collectible,
      },
    ],
    [collectible, summaryStats, collectibleId]
  );
  const breakpoint = useBreakpoint(true);

  return (
    <Table<Item, Item, number>
      className={className}
      items={items}
      columns={[
        createIndicatorColumn({
          getValue: (item) => item.summaryStats?.totalGrowthNumber ?? null,
        }),
        {
          key: 'collectible',
          renderTitle: (props) => (
            <DefaultTitle {...props} justify="start">
              {collectibleNames[collectibleType].shortSingular}
            </DefaultTitle>
          ),
          renderData: ({ collectible }) => {
            return (
              <CollectibleTableCell
                collectibleType={collectibleType}
                collectible={collectible}
                size="small"
                alternateGrades={alternateGrades}
                onGradeChange={onGradeChange}
              />
            );
          },
          width: 'minmax(220px, auto)',
        },
        {
          key: 'psa-link',
          renderData: (item, props) => {
            return (
              <TextCell>
                <Button
                  href={`${PSA_BASE_URL}/${item?.collectible?.serialNumber}`}
                  faIcon={faLink}
                  minWidth={160}
                  type="link"
                  target="_blank"
                >
                  PSA
                </Button>
              </TextCell>
            );
          },
        },
        createStatColumn({
          key: 'pop-count',
          title: 'Population Count',
          getValue: ({ summaryStats }) => summaryStats?.populationCount,
          type: 'count',
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'pop-higher',
          title: 'Population Higher',
          getValue: ({ summaryStats }) => summaryStats?.populationHigher,
          type: 'count',
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'change=#',
          title: 'Growth #',
          getValue: ({ summaryStats }) => summaryStats?.totalGrowthNumber,
          type: 'count',
          highlightChange: true,
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'change-%',
          title: 'Growth %',
          getValue: ({ summaryStats }) => summaryStats?.totalGrowthPercentage,
          type: 'percentage',
          highlightChange: true,
          width: statWidth,
          formatStatValue,
        }),
        {
          key: 'actions',
          renderData: () => (
            <TextCell>
              <CollectibleActionsRow
                onAddToCollection={actions.addToCollection}
                onAddPriceAlert={actions.addPriceAlert}
                onAddToFavorites={actions.addFavorite}
                onRemoveFromFavorites={actions.removeFavorite}
                isFavorite={actions.isFavorite}
                onForSale={actions.trackForSaleClick}
                forSaleUrl={actions.forSaleUrl}
                onChart={actions.chartCollectible}
                groupOnBreakpoints={{
                  xs: 0,
                  sm: 0,
                  md: 2,
                  ml: 2,
                  lg: 2,
                  xl: 2,
                }}
              />
            </TextCell>
          ),
          width: `minmax(${
            {
              xl: '208',
              lg: '208',
              ml: '208',
              md: '120',
              sm: '48',
              xs: '48',
            }[breakpoint!]
          }px, 208px)`,
        },
      ]}
      getRowKey={({ collectibleId }) => collectibleId}
      isLoading={isLoading}
    />
  );
}
