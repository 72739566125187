import { Select as AntdSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useAvailableGrades } from '../../grades/useAvailableGrades';
import { setCollectibleId } from './store';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import { CollectibleSportsCard } from '@sportscardinvestor/schemas';
import { useMemo } from 'react';
import clsx from 'clsx';
import { makeFilterOption } from '@/sci-ui-components/forms/SingleSelect/SingleSelect';

export function GradeOptionsSelect({
  collectible,
  className,
}: {
  collectible: CollectibleSportsCard;
  className?: string;
}) {
  const { formatStatValue } = useFormatStatValue();

  const { items: gradeOptions } = useAvailableGrades({
    player_id: collectible.player.id,
    set_id: collectible.set.id,
    variation_id: collectible.setVariation.variation.id,
    card_number: collectible.cardNumber,
    specific_qualifier: collectible.specificQualifier,
  });

  const gradeSelectOptions = useMemo(
    () =>
      gradeOptions?.map(({ grade_id, grade_name, all_end_avg, last_sale_date, population_count, card_id }) => ({
        gradeId: grade_id,
        gradeName: grade_name,
        cardId: card_id,
        currentPrice: all_end_avg,
        lastSaleDate: last_sale_date,
        populationCount: population_count,
      })) || [
        {
          gradeId: collectible.grade.id,
          gradeName: collectible.grade.name,
          cardId: collectible.id,
          currentPrice: collectible.stats.all.endAvgPrice,
          lastSaleDate: collectible.stats.lastSaleDate,
          populationCount: collectible.stats.currentPopulationCount,
        },
      ],
    [gradeOptions, collectible]
  );

  return (
    <AntdSelect
      showSearch
      placeholder="Card Grade"
      optionFilterProp="children"
      optionLabelProp="label"
      className={clsx(className, 'rounded-sm w-full border text-white bg-primary [&>*]:!cursor-pointer')}
      bordered={false}
      suffixIcon={
        <FontAwesomeIcon
          icon={faCaretDown}
          className="text-primary h-[30px] w-[30px] -mr-3 bg-white rounded-r-sm border border-primary"
        />
      }
      value={collectible.grade.id}
      filterOption={(input, option) => makeFilterOption(input)(input, option)}
      dropdownStyle={{ pointerEvents: 'auto' }}
      onChange={(_, o) => {
        if (!Array.isArray(o)) {
          setCollectibleId({ collectibleId: o.opt.cardId });
        }
      }}
    >
      {gradeSelectOptions.map((opt) => (
        <AntdSelect.Option key={opt.gradeId} value={opt.gradeId} label={opt.gradeName} opt={opt}>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <span className="max-w-[200px] truncate text-primary font-normal mr-auto">{opt.gradeName}</span>{' '}
              <span className="font-bold">
                {!!opt.currentPrice && formatStatValue({ value: opt.currentPrice, type: 'price' })}
              </span>
              <span className="text-xs text-white-bg-gray-muted">{formatDateInTimeZone(opt.lastSaleDate)}</span>
            </div>
            {!!opt.populationCount && (
              <span className="text-xs text-white-bg-gray-muted">(pop: {opt.populationCount})</span>
            )}
          </div>
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}
