import { MmApiInput, MmApiOutput, MmApiQueryOptions, mmApiClient, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

type UseAvailableBoxTypesByCollectibleInput =
  MmApiInput['private']['collectibles']['filters']['availableBoxTypesForSealed'];
type UseAvailableBoxTypesByCollectibleOutput =
  MmApiOutput['private']['collectibles']['filters']['availableBoxTypesForSealed'];
export const useAvailableBoxTypesKeyPrefix = 'private.collectibles.filters.availableBoxTypesForSealed' as const;
type UseAvailableBoxTypesByCollectibleKey = [
  typeof useAvailableBoxTypesKeyPrefix,
  UseAvailableBoxTypesByCollectibleInput
];

export function useAvailableBoxTypes(
  input: UseAvailableBoxTypesByCollectibleInput,
  options?: MmApiQueryOptions<UseAvailableBoxTypesByCollectibleOutput, UseAvailableBoxTypesByCollectibleKey>
) {
  const key: UseAvailableBoxTypesByCollectibleKey = [useAvailableBoxTypesKeyPrefix, input];
  const result = useAuthenticatedMMAPIQuery(
    key,
    () => mmApiClient.private.collectibles.filters.availableBoxTypesForSealed.query(input),
    options
  );

  return result;
}
