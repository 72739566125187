import { CollectibleType } from '@/sci-ui-components/types/collectibleType';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import { useCallback } from 'react';

export type UseCollectionQuantitiesInput = Exclude<
  MmApiInput['private']['collection']['aggregatedItems']['allQuantities'],
  void
>;
export type UseCollectionQuantitiesOutput = MmApiOutput['private']['collection']['aggregatedItems']['allQuantities'];

export const useCollectionQuantitiesKeyPrefix = 'private.collection.aggregatedItems.allQuantities' as const;
export type UseCollectionQuantitiesKey = [typeof useCollectionQuantitiesKeyPrefix, UseCollectionQuantitiesInput];
export const makeCollectionQuantitiesQueryKey = (input: UseCollectionQuantitiesInput): UseCollectionQuantitiesKey => [
  useCollectionQuantitiesKeyPrefix,
  input,
];

export function useCollectionQuantities(
  input: UseCollectionQuantitiesInput,
  options?: MmApiQueryOptions<UseCollectionQuantitiesOutput, UseCollectionQuantitiesKey>
) {
  const { data: { items: quantityByCollectibleId } = {}, ...otherResult } = useAuthenticatedMMAPIQuery(
    makeCollectionQuantitiesQueryKey(input),
    async () => mmApiClient.private.collection.aggregatedItems.allQuantities.query(input),
    options
  );
  const getQuantityByCollectibleId = useCallback(
    (collectibleId: number | null) => (collectibleId ? quantityByCollectibleId?.[collectibleId] ?? 0 : 0),
    [quantityByCollectibleId]
  );

  return {
    ...otherResult,
    quantityByCollectibleId,
    getQuantityByCollectibleId,
  };
}

export function useCollectibleCollectionQuantity({
  collectibleId,
  collectibleType,
}: {
  collectibleId: number | null;
  collectibleType: CollectibleType;
}) {
  const {
    getQuantityByCollectibleId,
    quantityByCollectibleId: _,
    ...rest
  } = useCollectionQuantities(
    {
      collectibleType,
    },
    {
      enabled: !!collectibleId,
    }
  );
  return {
    ...rest,
    quantity: getQuantityByCollectibleId(collectibleId),
  };
}
