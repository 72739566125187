import { useEffect, useMemo, useState } from 'react';
import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@sportscardinvestor/sci-ui/src/components/ui/collapsible';
import NextLink from 'next/link';
import { formatDistance, parseISO } from 'date-fns';
import { parseSalePlatform, salePlatformsInfo } from '@sportscardinvestor/schemas';
import usePaginationState, { PaginationStateConfig } from '../../../hooks/usePaginationState';
import { useCommonCollectibleActions } from '../useCommonCollectiblesActions';
import classes from './CollectiblesForSale.module.scss';
import { closeCollectibleFlyout, useCollectibleFlyoutStoreSelector } from './store';
import Pagination from '@/sci-ui-components/Pagination/Pagination';
import { ebayAffiliateDisclosure } from '@/constants';
import CollectibleImage from '@/sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import VerticalTilesList from '@/sci-ui-components/VerticalTilesList/VerticalTilesList';
import ListingTypeToggle from '@/sci-ui-components/buttons/ListingTypeToggle/ListingTypeToggle';
import useFormatStatValue from 'hooks/useFormatStatValue';
import { useCollapsibleStateFromStorage } from '@/features/collectibles/CollectibleDetailsFlyout/helpers';
import { generateCollectiblesChartHref } from '@/features/search/utils/collectibleChart';
import { ForSaleItemType } from '@/features/sales/useForSaleList';
import useAccessFlags from 'hooks/useAccessFlags';
import useAuth from '@/features/auth/useAuth';
import { showSignupIncentiveModal } from '@/features/signupIncentive';
import { trackEvent } from '@/features/analytics/trackEvent';
import useForSaleListPaginated from '@/features/sales/useForSaleList';

const paginationConfig: PaginationStateConfig = { limitOptions: [3], defaultLimit: 3 };

export default function CollectiblesForSales() {
  const { collectibleId, collectibleType } = useCollectibleFlyoutStoreSelector('collectibleId', 'collectibleType');
  const { isLoggedIn } = useAuth();
  const isPublic = !isLoggedIn;

  const [open, setOpen] = useCollapsibleStateFromStorage('ForSale');
  const { formatStatValue } = useFormatStatValue();

  const viewAllHref = `${generateCollectiblesChartHref({
    collectibleIds: collectibleId ? [collectibleId] : [],
    collectibleType,
  })}#for_sale`;

  const isAuctionsTabAvailable = useAccessFlags({ flag: 'isAuctionsTabAvailable' });

  const actions = useCommonCollectibleActions({
    collectibleId,
    collectibleType,
  });

  const [forSaleItemType, setForSaleItemType] = useState<ForSaleItemType>(
    isAuctionsTabAvailable ? 'Auctions' : 'FixedPrice'
  );

  const { limit, setPage, page, offset } = usePaginationState(paginationConfig);

  const { data, isLoading } = useForSaleListPaginated(
    {
      filters: {
        collectibleIds: [collectibleId!],
        listingTypes: forSaleItemType === 'Auctions' ? ['Auction'] : ['Store', 'AuctionWithBin'],
      },
      sort: [{ sortBy: forSaleItemType === 'Auctions' ? 'endTime' : 'buyItNowPrice', sortDirection: 'asc' }],
      collectibleType,
      limit,
      offset,
    },
    {
      keepPreviousData: true,
    }
  );

  const items = data?.items ?? null;
  const totalItems = data?.totalCount;

  const hasListingResults = useMemo(() => {
    if (items !== null && items.length > 0) {
      return true;
    }
    return false;
  }, [items]);

  useEffect(() => {
    setPage(0);
  }, [setPage, forSaleItemType]);

  return (
    <div className={classes.root}>
      <Collapsible open={open} onOpenChange={setOpen}>
        <div className="flex flex-col">
          <CollapsibleTrigger asChild>
            <Button
              variant="ghost"
              size="lg"
              className="pl-0 pr-0 mb-4 -mt-1 font-black uppercase text-[24px] h-[32px] hover:bg-transparent"
            >
              <div className="flex flex-row gap-4 mr-auto items-center">
                <span>For Sale</span>
                {hasListingResults && !!viewAllHref && !!collectibleId && (
                  <NextLink href={viewAllHref} legacyBehavior>
                    <a
                      className="font-bold text-lg mr-auto uppercase text-primary"
                      onClick={(e) => {
                        if (isPublic) {
                          showSignupIncentiveModal();
                          e.preventDefault();
                        } else {
                          closeCollectibleFlyout();
                          trackEvent({
                            eventName: 'SALES_NAVIGATED_TO_VIEW_ALL_AVAILABLE_SALES',
                            collectibleId,
                            collectibleType,
                          });
                        }
                      }}
                    >
                      View All
                    </a>
                  </NextLink>
                )}
              </div>
              <FontAwesomeIcon icon={open ? faCaretDown : faCaretLeft} />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            {isAuctionsTabAvailable && (
              <ListingTypeToggle
                forSaleItemType={forSaleItemType}
                onForSaleItemTypeChange={setForSaleItemType}
                buttonSize="small"
                buttonClassName={classes.auctionButton}
                containerClassName={classes.auctionButtonsContainer}
              />
            )}
            <div className={classes.affiliateText}>{ebayAffiliateDisclosure}.</div>
            <VerticalTilesList isLoading={isLoading} emptyMessage="No Listings Found" listClassName={classes.list}>
              {items?.map(
                ({
                  imageUrl,
                  saleTitle,
                  buyItNowPrice,
                  endTime,
                  salePrice,
                  saleId,
                  saleUrl,
                  isBestOfferEnabled,
                  salePlatform,
                }) => {
                  const price = forSaleItemType === 'Auctions' ? salePrice : buyItNowPrice;
                  const sellerInfo =
                    salePlatformsInfo[
                      salePlatform === 'raw' ? parseSalePlatform({ platformName: salePlatform }) : salePlatform
                    ];

                  return (
                    <div className={classes.item} key={saleId}>
                      <CollectibleImage
                        className={classes.image}
                        url={imageUrl}
                        alt={saleTitle}
                        collectibleType={collectibleType}
                      />
                      <div className={classes.detailsContainer}>
                        <div className={classes.details}>
                          <p className={classes.sellerInfo}>
                            {sellerInfo.isKnownSeller ? ` (${sellerInfo.sellerName})` : ''}
                            {` - ${forSaleItemType === 'FixedPrice' ? 'Buy it Now' : 'Bid Now'}${
                              isBestOfferEnabled ? ' or Make an Offer' : ''
                            }`}
                          </p>
                          {forSaleItemType === 'Auctions' && (
                            <p className={classes.sellerInfo}>
                              {`Ends in ${
                                endTime
                                  ? formatDistance(parseISO(endTime), new Date(), {
                                      includeSeconds: true,
                                    }).replace('about', '')
                                  : 'N/A'
                              }`}
                            </p>
                          )}
                        </div>
                        <div className={classes.details}>
                          <a
                            href={saleUrl ?? ''}
                            className={classes.itemTitle}
                            onClick={actions.trackForSaleClick}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {saleTitle}
                          </a>
                          <p className={classes.price}>
                            {formatStatValue({
                              value: price,
                              type: 'price',
                              defaultValue: 'N/A',
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              ) ?? []}
            </VerticalTilesList>
            {totalItems && totalItems > limit ? (
              <Pagination
                className="mt-4"
                onPageChange={setPage}
                totalItems={totalItems}
                page={page}
                limit={limit}
                noPageJumps
                justify="right"
              />
            ) : null}
          </CollapsibleContent>
        </div>
      </Collapsible>
    </div>
  );
}
