import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { useCollectibleFlyoutStoreSelector, setDateRangeInDays } from './store';
import { NUMERIC_DATE_RANGE_OPTIONS, isNumericDateRange } from '@/sci-ui-components/utils/date';
import { showSignupIncentiveModal } from '@/features/signupIncentive';
import useAuth from '@/features/auth/useAuth';

export default function FlyoutDateRangeSelector() {
  const { dateRangeInDays } = useCollectibleFlyoutStoreSelector('dateRangeInDays');
  const { isLoggedIn } = useAuth();

  return (
    <div className="flex flex-row justify-between">
      {NUMERIC_DATE_RANGE_OPTIONS.map((n) => (
        <Button
          key={n}
          value={n}
          variant="outline"
          className={`bg-white rounded-full text-lg h-[28px] w-[60px] border-gray-primary ${
            dateRangeInDays === n && 'bg-gray-primary text-white hover:bg-gray-primary hover:text-white'
          }`}
          onClick={(e) => {
            const n = parseInt(e.currentTarget.value);
            if (n > 30 && !isLoggedIn) showSignupIncentiveModal();
            else if (isNumericDateRange(n)) setDateRangeInDays(n);
          }}
        >
          {n}
        </Button>
      ))}
    </div>
  );
}
