import clsx from 'clsx';
import { ErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import { CollectibleDetails } from './CollectibleDetailsFlyout';
import { closeCollectibleFlyout, CollectibleFlyoutQueryStringSync, useCollectibleFlyoutStoreSelector } from './store';

export default function CollectibleDetailsModalProvider() {
  const { collectibleId, collectibleType, isCustom } = useCollectibleFlyoutStoreSelector(
    'collectibleId',
    'collectibleType',
    'isCustom'
  );
  return (
    <ErrorBoundary dependencies={[collectibleId, collectibleType, isCustom]}>
      <div className="twp pointer-events-none z-modal fixed top-0 bottom-0 left-0 right-0">
        <div
          className={`w-full h-full backdrop-brightness-0 transition ${
            collectibleId ? 'backdrop-opacity-45 pointer-events-auto' : 'backdrop-opacity-0 pointer-events-none'
          }`}
          role="button"
          onClick={closeCollectibleFlyout}
        />

        <div
          className={clsx(
            'w-[500px] max-w-[calc(100vw-52px)] 2xl:w-[34vw]',
            'right-[-500px] 2xl:right-[-34vw]',
            'absolute top-0 h-full z-modal bg-aside shadow-[-6px_0_16px_#0000001f] transform duration-300',
            {
              'pointer-events-auto -translate-x-[500px] 2xl:-translate-x-[34vw]': !!collectibleId,
              'pointer-events-none': !collectibleId,
            }
          )}
        >
          <div className="flex h-full pt-[60px]">
            <CollectibleDetails />
          </div>
        </div>
      </div>
      <CollectibleFlyoutQueryStringSync />
    </ErrorBoundary>
  );
}
