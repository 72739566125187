import { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ModalDialog from '../../../../sci-ui-components/ModalDialog/ModalDialog';
import SavedSearchCategorySelect from '../SavedSearchCategorySelect/SavedSearchCategorySelect';
import useSavedSearchesMutations from '../useSavedSearchesMutations';
import { editOrAddSavedSearchCategory } from '../EditSavedSearchCategoryDialog/index';
import { EditSavedSearchDialogProps, Mode } from './types';
import TextField from 'sci-ui-components/forms/TextField/TextField';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import AddNewAdornmentButton from 'sci-ui-components/buttons/AddNewAdornmentButton/AddNewAdornmentButton';

const formId = 'edit-saved-search';
const categoryFieldId = `${formId}-category`;
const titleFieldId = `${formId}-title`;

type FormValues = {
  categoryId?: string | null;
  title?: string;
};

export default function EditSavedSearchDialog({
  isOpen,
  onClose,
  onAdd,
  mode = 'all',
  savedSearchId,
  categoryId,
  title,
  collectibleType,
}: EditSavedSearchDialogProps & {
  isOpen: boolean;
  onClose: () => void;
  onAdd?: () => void;
}) {
  const { update } = useSavedSearchesMutations({ collectibleType: collectibleType! });

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (!savedSearchId) {
      console.error('Somehow EditSavedSearchDialog is submitted wheen there is no saved search to edit');
      return; // should never happen
    }
    await update.mutateAsync({
      id: savedSearchId,
      categoryId: values.categoryId,
      title: values.title,
    });
    onAdd?.();
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const { initialValues, validationSchema } = useMemo(
    () =>
      getFormConfig({
        mode,
        title,
        categoryId,
      }),
    [mode, title, categoryId]
  );

  const visible = isOpen && !!savedSearchId && !!collectibleType;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <>
          <ModalDialog
            open={visible}
            title={getFormTitle(mode)}
            okText="Save"
            onCancel={onClose}
            cancelText="Cancel"
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
            cancelButtonProps={{
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FormSection variant="secondary">
                {['all', 'rename'].includes(mode) && (
                  <FieldLabelBox label="Title" fieldId={titleFieldId} error={errors.title}>
                    <TextField
                      value={values.title ?? ''}
                      name="title"
                      onChange={(value, name) => handleChange({ target: { value, name } })}
                      placeholder="Enter title.."
                      id={titleFieldId}
                    />
                  </FieldLabelBox>
                )}
                {['all', 'change-category'].includes(mode) && (
                  <FieldLabelBox
                    label="Category"
                    fieldId={categoryFieldId}
                    error={errors.categoryId}
                    adornmentRight={
                      <AddNewAdornmentButton
                        onClick={async () => {
                          const maybeNewCategory = await editOrAddSavedSearchCategory({ collectibleType });
                          if (maybeNewCategory) {
                            handleChange({ target: { name: 'categoryId', value: maybeNewCategory.id } });
                          }
                        }}
                      />
                    }
                  >
                    <SavedSearchCategorySelect
                      onChange={(categoryId) => handleChange({ target: { name: 'categoryId', value: categoryId } })}
                      value={values.categoryId}
                      id={categoryFieldId}
                      collectibleType={collectibleType}
                      noValueLabel="Not Selected"
                    />
                  </FieldLabelBox>
                )}
              </FormSection>
            </Form>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}

function getFormConfig({ mode, categoryId, title }: { mode: Mode; categoryId?: string; title?: string }): {
  initialValues: FormValues;
  validationSchema: Yup.SchemaOf<FormValues>;
} {
  switch (mode) {
    case 'all': {
      const initialValues = {
        categoryId,
        title,
      };
      const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
        categoryId: Yup.string().nullable(),
        title: Yup.string().required('Required'),
      });
      return {
        initialValues,
        validationSchema,
      };
    }
    case 'rename': {
      const initialValues = {
        title,
      };
      const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
        categoryId: Yup.string().nullable(),
        title: Yup.string().required('Required'),
      });
      return {
        initialValues,
        validationSchema,
      };
    }
    case 'change-category': {
      const initialValues = {
        categoryId,
      };
      const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
        categoryId: Yup.string().nullable(),
        title: Yup.string(),
      });
      return {
        initialValues,
        validationSchema,
      };
    }
  }
}

function getFormTitle(mode: Mode) {
  switch (mode) {
    case 'all':
      return 'Edit Saved Search';
    case 'rename':
      return 'Rename Saved Search';
    case 'change-category':
      return 'Move to Category';
  }
}
