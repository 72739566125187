import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { Select, SelectProps, RefSelectProps } from 'antd';
import clsx from 'clsx';

import { useSelectPopupContainer } from '../SimpleSelect/useSelectPopupContainer';
import { SimpleSelectProps } from '../SimpleSelect/SimpleSelect';

import classes from '../SimpleSelect/SimpleSelect.module.scss';
import makeSearchTextRegex from '@/sci-ui-components/utils/makeSearchTextRegex';

export type Option<TValue extends string | number = string> = {
  value: TValue;
  label: string;
  disabled?: boolean;
};

export function makeFilterOption(input: string): Exclude<SelectProps['filterOption'], undefined | boolean> {
  const regex = makeSearchTextRegex(input);
  return (_input, option) => {
    if (!option) return false;
    const optionValue = option.label?.toString() ?? '';
    return regex.test(optionValue);
  };
}

const defaultFilterOption: SelectProps['filterOption'] = (i, o) => makeFilterOption(i)(i, o);

export interface SingleSelectProps<TValue extends string | number = string>
  extends Omit<SimpleSelectProps<TValue>, 'onChange' | 'options' | 'renderOption' | 'filterOption'> {
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  isLoading?: boolean;
  fieldLabel?: string;
  fieldLabelPlacement?: 'inline' | 'above';
  onSearch?: (search: string) => void;
  onFocus?: () => void;
  options: Readonly<Option<TValue>[]>;
  onChange: (newValue: TValue | null) => void;
  filterOption?: SelectProps['filterOption'] | false | null;
}

function SingleSelect<TValue extends string | number = string>(
  {
    className,
    value,
    options,
    onChange,
    noValueLabel = 'Not Selected',
    id,
    notEmpty,
    disabled,
    allowClear,
    showSearch,
    onSearch,
    onFocus,
    isLoading,
    fieldLabel,
    fieldLabelPlacement,
    filterOption = defaultFilterOption,
  }: SingleSelectProps<TValue>,
  ref: React.ForwardedRef<RefSelectProps>
) {
  const { getPopupContainer } = useSelectPopupContainer();

  return (
    <div
      className={clsx(
        classes.selectWrapper,
        { [classes.fieldLabelInline]: fieldLabelPlacement === 'inline' },
        className
      )}
    >
      {!!fieldLabel && <label>{fieldLabel}</label>}
      <Select
        ref={ref}
        onChange={(value) => onChange(value === 'null' ? null : value)}
        allowClear={allowClear}
        showSearch={showSearch}
        onSearch={onSearch}
        filterOption={filterOption ?? false}
        value={value}
        onFocus={onFocus}
        className={classes.select}
        id={id}
        disabled={disabled}
        loading={isLoading}
        getPopupContainer={getPopupContainer}
        {...(isLoading ? {} : { suffixIcon: <FontAwesomeIcon icon={faSortDown} /> })}
      >
        {!notEmpty && <Select.Option value={'null'}>{noValueLabel}</Select.Option>}
        {options.map(({ label, value, disabled }) => (
          <Select.Option key={value} value={value} disabled={disabled}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default React.forwardRef(SingleSelect) as <TValue extends string | number = string>(
  props: SingleSelectProps<TValue> & { ref?: React.ForwardedRef<RefSelectProps> }
) => ReturnType<typeof SingleSelect>;
