import React from 'react';
import clsx from 'clsx';

export function Badge({
  children,
  count,
  className,
  maxCount,
}: {
  children: React.ReactNode;
  count: number | null | undefined;
  className?: string;
  maxCount?: number;
}) {
  return (
    <div className={clsx('relative', className)}>
      {children}
      {!!count && (
        <div className="absolute top-0 right-0 w-4 h-4 flex flex-row items-center justify-center rounded-full bg-red-600 pointer-events-none">
          <span className="text-lightFg-inverse text-xs font-semibold">
            {maxCount ? Math.min(count, maxCount) : count}
          </span>
        </div>
      )}
    </div>
  );
}
