import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollar, faStar, faSquarePlus, faBell, faChartColumn } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { useCommonCollectibleActions } from '../useCommonCollectiblesActions';
import { useCollectibleFlyoutStoreSelector, closeCollectibleFlyout } from './store';
import RookieIndicator from '@/sci-ui-components/sport-cards/RookieIndicator/RookieIndicator';
import CollectibleImageModal from '@/sci-ui-components/collectibles/CollectibleImageModal/CollectibleImageModal';
import CollectibleText from '@/sci-ui-components/collectibles/CollectibleText/CollectibleText';
import PopulationCount from '@/sci-ui-components/collectibles/PopulationCount/PopulationCount';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';
import DashboardStat from '@/sci-ui-components/stats/DashboardStat/DashboardStat';
import { ebayAffiliateDisclosure } from '@/constants';
import { useCollectibleCollectionQuantity } from '@/features/collection/useCollectionQuantities';
import { Badge } from '@/sci-ui-components/Badge';
import { Collectible } from '@sportscardinvestor/schemas';
import {
  isSealedCollectible,
  isSportsCardCollectible,
  makeCollectibleDescriptions,
} from '@sportscardinvestor/collectible-helpers';
import { VariationOptionsSelect } from './VariationOptionsSelect';
import { GradeOptionsSelect } from './GradeOptionsSelect';
import { BoxTypeOptionsSelect } from './BoxTypeOptionsSelect';
import { getCollectibleImageUrl } from '@sportscardinvestor/collectible-helpers';

interface NonCustomInfoActions {
  forSaleUrl: string | null;
  onChart: () => void;
  chartPopulationCount: () => void;
  trackForSaleClick: () => void;
  onAddToFavorites: () => void;
  onRemoveFromFavorites: () => void;
  isFavorite: boolean;
  hasAlert: boolean;
  onAddToCollection: () => void;
  onAddPriceAlert: () => void;
}

export default function NonCustomCollectibleInfo({ nonCustomCollectible }: { nonCustomCollectible: Collectible }) {
  const { collectibleId, collectibleType, dateRangeInDays } = useCollectibleFlyoutStoreSelector(
    'collectibleId',
    'collectibleType',
    'dateRangeInDays'
  );

  const { stats } = nonCustomCollectible;
  const statsForDateRange = stats?.[`last${dateRangeInDays}`] ?? null;

  const commonActions = useCommonCollectibleActions({
    collectibleId,
    collectibleType,
    query: nonCustomCollectible.query,
  });

  const actions: NonCustomInfoActions = {
    forSaleUrl: commonActions.forSaleUrl,
    trackForSaleClick: () => commonActions.forSaleUrl && commonActions.trackForSaleClick,
    onAddToCollection: commonActions.addToCollection,
    onChart: async () => {
      const hasGoneToChartPage = await commonActions.chartCollectible();
      if (hasGoneToChartPage) {
        closeCollectibleFlyout();
      }
    },
    hasAlert: commonActions.hasAlert,
    isFavorite: commonActions.isFavorite,
    onAddPriceAlert: commonActions.addPriceAlert,
    onAddToFavorites: commonActions.addFavorite,
    onRemoveFromFavorites: commonActions.removeFavorite,
    chartPopulationCount: commonActions.chartPopulationCount,
  };

  const { title, descriptionLine1 } = makeCollectibleDescriptions(nonCustomCollectible);

  const { quantity: quantityInCollection } = useCollectibleCollectionQuantity({
    collectibleType,
    collectibleId,
  });

  return (
    <div className="flex gap-4 mr-4 pr-4 w-full">
      <CollectibleImageModal
        className="w-48"
        url={getCollectibleImageUrl(nonCustomCollectible)}
        alt={title}
        collectibleType={collectibleType}
      />
      <div className="flex flex-1 flex-col gap-1 justify-between">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <CollectibleText type="title" size="large" className="text-ellipsis">
              {title}
            </CollectibleText>
            {isSportsCardCollectible(nonCustomCollectible) && (
              <RookieIndicator isRookie={nonCustomCollectible.isRookie} />
            )}
          </div>
          <CollectibleText type="description" size="medium">
            {descriptionLine1}
          </CollectibleText>
          {isSportsCardCollectible(nonCustomCollectible) && (
            <>
              <VariationOptionsSelect collectible={nonCustomCollectible} />
              <GradeOptionsSelect collectible={nonCustomCollectible} />
              <PopulationCount
                count={nonCustomCollectible.stats.currentPopulationCount}
                size="large"
                onClick={actions.chartPopulationCount}
                className="mb-1"
              />
            </>
          )}
          {isSealedCollectible(nonCustomCollectible) && <BoxTypeOptionsSelect collectible={nonCustomCollectible} />}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between max-w-[260px]">
            <Button
              variant="ghost"
              className="text-2xl text-[#666666] p-2 hover:bg-transparent"
              title="Chart this Collectible"
              onClick={actions.onChart}
            >
              <FontAwesomeIcon icon={faChartColumn} />
            </Button>
            <Button
              asChild
              variant="ghost"
              className="text-2xl text-[#666666] p-2 hover:bg-transparent"
              title={`For Sale \n(${ebayAffiliateDisclosure})`}
              onClick={actions.trackForSaleClick}
            >
              <a href={actions.forSaleUrl || undefined} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faCircleDollar} />
              </a>
            </Button>
            <Button
              variant="ghost"
              title={actions.isFavorite ? 'Remove From Favorites' : 'Add To Favorites'}
              className={`text-2xl ${
                actions.isFavorite ? 'text-primary hover:text-[#2982ff]' : 'text-[#666666]'
              } p-2 hover:bg-transparent`}
              onClick={() => (actions.isFavorite ? actions.onRemoveFromFavorites() : actions.onAddToFavorites())}
            >
              <FontAwesomeIcon icon={faStar} />
            </Button>
            <Badge count={quantityInCollection}>
              <Button
                variant="ghost"
                title="Add To Collection"
                className={`text-2xl ${
                  quantityInCollection ? 'text-primary hover:text-[#2982ff]' : 'text-[#666666]'
                } p-2 hover:bg-transparent`}
                onClick={actions.onAddToCollection}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
              </Button>
            </Badge>
            <Button
              variant="ghost"
              title="Add Price Alert"
              className={`text-2xl ${
                actions.hasAlert ? 'text-primary hover:text-[#2982ff]' : 'text-[#666666]'
              } p-2 hover:bg-transparent`}
              onClick={actions.onAddPriceAlert}
            >
              <FontAwesomeIcon icon={faBell} />
            </Button>
          </div>
          <div className="flex flex-col gap-1">
            {stats?.lastSaleDate && (
              <span className="text-xs text-gray-muted">{formatDateInTimeZone(stats?.lastSaleDate)}</span>
            )}
            <div className="flex flex-row gap-2 -mb-1">
              <DashboardStat
                value={stats?.all?.endAvgPrice}
                type="price"
                variant="context"
                size="medium"
                textClassName="!font-semibold"
              />
              {!!statsForDateRange?.priceChangePercentage && (
                <DashboardStat
                  value={statsForDateRange?.priceChangePercentage}
                  type="percentage"
                  variant="context"
                  size="small"
                  highlightChange
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
