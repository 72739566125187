import { useCallback, useMemo } from 'react';
import { getCollectionCollectible } from '@sportscardinvestor/collection-helpers';
import { getCollectibleId, isCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations_deprecated from '../deprecated_useCollectionItemMutations';
import { useCollectionItem_deprecated } from '../deprecated_useListCollectionItems';
import { useEditCustomCollectionItemDialogStore } from './store';
import {
  EditCustomCollectionItemForm,
  getEditCustomCollectionItemFormInitialValues,
  type OnEditCustomCollectionItemFormSubmitFn,
} from './forms/EditCustomCollectionItemForm';
import { EditCustomCollectionItemCallbacks } from './forms/callbacks';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import { trackEvent } from '@/features/analytics/trackEvent';

export const EditCustomCollectionItemDialog = withErrorBoundary(function EditCustomCollectionItemDialog() {
  const { collectionItemId, collectibleType, close, salesHistoryItem } = useEditCustomCollectionItemDialogStore();
  const { collectionItem } = useCollectionItem_deprecated({
    collectibleType,
    collectionItemId,
  });
  const collectible = collectionItem ? getCollectionCollectible(collectionItem) : null;
  const customCollectible = collectible && isCustomCollectible(collectible) ? collectible : null;
  const collectibleId = customCollectible ? getCollectibleId(customCollectible) : null;
  const { updateCollectionItem } = useCollectionItemMutations_deprecated();

  const initialValues = useMemo(
    () =>
      getEditCustomCollectionItemFormInitialValues({
        collectibleType,
        customCollectible: customCollectible ?? null,
      }),
    [customCollectible, collectibleType]
  );
  const handleSubmit = useCallback<OnEditCustomCollectionItemFormSubmitFn>(
    (payload) => {
      if (!collectibleId) {
        throw new Error('Cannot edit cusom collection item - no collectible ID'); // NOTE: Should never happen
      }
      if (payload.collectibleType === 'sealed-wax-card') {
        const { imageUrl, setId, setName, setYear, sportName, boxTypeName } = payload;
        updateCollectionItem.mutate(
          {
            customCollectible: {
              collectibleId,
              collectibleType,
              boxTypeName,
              sportName,
              cardSetYear: setYear,
              cardSetName: setName,
              cardSetId: setId,
              imageUrl: imageUrl ?? undefined,
            },
            collectibleType,
          },
          {
            onSuccess: close,
          }
        );
      } else if (payload.collectibleType === 'sports-card') {
        const {
          cardNumber,
          gradeName,
          imageUrl,
          playerId,
          playerName,
          setId,
          setName,
          setYear,
          specificQualifier,
          sportName,
          variationName,
        } = payload;
        updateCollectionItem.mutate(
          {
            customCollectible: {
              collectibleId,
              collectibleType,
              variationName,
              sportName,
              playerName,
              playerId,
              gradeName,
              cardSetYear: setYear,
              cardSetName: setName,
              cardSetId: setId,
              imageUrl: imageUrl ?? undefined,
              cardNumber,
              specificQualifier,
            },
            collectibleType,
          },
          {
            onSuccess: () => {
              trackEvent({
                eventName: 'COLLECTION_EDIT_CARD_COMPLETED',
                collectibleType,
              });
              close();
            },
            onError: () => {
              trackEvent({
                eventName: 'COLLECTION_EDIT_CARD_FAILED',
                collectibleType,
              });
            },
          }
        );
      }
    },
    [collectibleId, close, collectibleType, updateCollectionItem]
  );
  const handleCancel = useCallback(() => {
    trackEvent({
      eventName: 'COLLECTION_EDIT_CARD_CANCELLED',
      collectibleType,
      collectionItemId,
    });
    close();
  }, [close, collectibleType, collectionItemId]);

  const callbacks = useMemo<EditCustomCollectionItemCallbacks>(
    () => ({
      onPhotoSelected: () =>
        trackEvent({
          eventName: 'COLLECTION_EDIT_CARD_UPDATE_IMAGE',
          collectibleType,
        }),
    }),
    [collectibleType]
  );

  const collectibleName = collectibleNames[collectibleType].shortSingular;

  return (
    <ModalDialog
      open={!!collectionItem && !!collectibleId}
      title={`Edit Manual ${collectibleName}`}
      onCancel={handleCancel}
      footer={null}
      variant="primary"
      width="fit-content"
      withHeaderUnderline
    >
      <EditCustomCollectionItemForm
        initialValues={initialValues}
        isLoading={updateCollectionItem.isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        salesHistoryItem={salesHistoryItem}
        {...callbacks}
      />
    </ModalDialog>
  );
});
