import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ModalDialog from '../../../../sci-ui-components/ModalDialog/ModalDialog';
import SavedSearchCategorySelect from '../SavedSearchCategorySelect/SavedSearchCategorySelect';
import useSavedSearchesMutations from '../useSavedSearchesMutations';
import { editOrAddSavedSearchCategory } from '../EditSavedSearchCategoryDialog/index';
import { AddSavedSearchDialogProps } from './types';
import TextField from 'sci-ui-components/forms/TextField/TextField';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import AddNewAdornmentButton from 'sci-ui-components/buttons/AddNewAdornmentButton/AddNewAdornmentButton';

const formId = 'add-saved-search';
const categoryFieldId = `${formId}-category`;
const titleFieldId = `${formId}-title`;

type FormValues = {
  categoryId?: string | null;
  title: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  categoryId: Yup.string().nullable(),
  title: Yup.string().required('Required'),
});

const initalValues: FormValues = {
  categoryId: null,
  title: '',
};

export default function AddSavedSearchDialog({
  isOpen,
  onClose,
  onAdd,
  collectibleIds,
  collectibleType,
  savedSearchType,
  savedSearchQuery,
}: AddSavedSearchDialogProps & {
  isOpen: boolean;
  onClose: () => void;
  onAdd?: () => void;
}) {
  const { create } = useSavedSearchesMutations({ collectibleType: collectibleType! });
  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (!collectibleIds?.length && !savedSearchQuery) {
      return;
    }
    await create.mutateAsync({
      categoryId: values.categoryId ?? null,
      title: values.title,
      collectibleType: collectibleType!,
      collectibleIds: collectibleIds ?? [],
      savedSearchType,
      savedSearchQuery,
    });

    onAdd?.();
    onClose();
    setSubmitting(false);
    resetForm();
  };
  const visible = isOpen && (!!collectibleIds?.length || !!savedSearchQuery) && !!collectibleType;

  return (
    <Formik
      initialValues={initalValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <>
          <ModalDialog
            open={visible}
            title="Save Selection"
            okText="Save"
            onCancel={onClose}
            cancelText="Cancel"
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
            cancelButtonProps={{
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FormSection variant="secondary">
                <FieldLabelBox label="Title" fieldId={titleFieldId} error={errors.title}>
                  <TextField
                    value={values.title ?? ''}
                    name="title"
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                    placeholder="Enter title.."
                    id={titleFieldId}
                  />
                </FieldLabelBox>
                <FieldLabelBox
                  label="Category"
                  fieldId={categoryFieldId}
                  error={errors.categoryId}
                  adornmentRight={
                    <AddNewAdornmentButton
                      onClick={async () => {
                        const maybeNewCategory = await editOrAddSavedSearchCategory({
                          collectibleType: collectibleType!,
                          savedSearchType,
                        });
                        if (maybeNewCategory) {
                          handleChange({ target: { name: 'categoryId', value: maybeNewCategory.id } });
                        }
                      }}
                    />
                  }
                >
                  <SavedSearchCategorySelect
                    onChange={(categoryId) => handleChange({ target: { name: 'categoryId', value: categoryId } })}
                    value={values.categoryId}
                    id={categoryFieldId}
                    collectibleType={collectibleType}
                    savedSearchType={savedSearchType}
                  />
                </FieldLabelBox>
              </FormSection>
            </Form>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}
