import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import { NoCategory } from './constants';

export type CollectionDimensionValuesInput = Exclude<MmApiInput['private']['collection']['dimensionValues'], void>;
export type CollectionDimensionValuesOutput = Exclude<MmApiOutput['private']['collection']['dimensionValues'], void>;

export const useCollectionDimensionValuesKey = 'private.collection.dimensionValues';
type QueryKey = [typeof useCollectionDimensionValuesKey, CollectionDimensionValuesInput];

export const getUseCollectionDimensionValuesQueryKey = (input: CollectionDimensionValuesInput): QueryKey => [
  useCollectionDimensionValuesKey,
  input,
];

export function useCollectionDimensionValues(
  inputParam: CollectionDimensionValuesInput,
  options?: MmApiQueryOptions<CollectionDimensionValuesOutput, QueryKey>
) {
  const input: CollectionDimensionValuesInput = {
    ...inputParam,
    categoryId: inputParam.categoryId === NoCategory ? 'noCategory' : inputParam.categoryId,
  };
  const result = useAuthenticatedMMAPIQuery(
    getUseCollectionDimensionValuesQueryKey(input),
    () => mmApiClient.private.collection.dimensionValues.query(input),
    options
  );

  return result;
}
