import { useCallback, useMemo } from 'react';
import {
  mmApiClient,
  MmApiInfiniteQueryOptions,
  MmApiInput,
  MmApiOutput,
  MmApiQueryOptions,
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
} from 'services/mmApiX';

export type ForSaleInput = Exclude<MmApiInput['private']['sales']['active'], void>;
export type ForSaleListListSortBy = Exclude<ForSaleInput['sort'], undefined>[number]['sortBy'];
export type ForSaleOutput = MmApiOutput['private']['sales']['active'];
export type ForSaleItem = Exclude<ForSaleOutput, void | null>['items'][number];
export type ForSaleItemType = Exclude<Exclude<ForSaleItem, void | null>['listingType'], null>;

export const keyPrefix = 'private.sales.active';
type QueryKey =
  | [typeof keyPrefix, ForSaleInput]
  | [typeof keyPrefix, 'count', ForSaleInput]
  | [typeof keyPrefix, 'items', ForSaleInput];

export function useForSaleListInfinite(
  input: Omit<ForSaleInput, 'offset' | 'listingTypes'>,
  options?: MmApiInfiniteQueryOptions<ForSaleOutput, QueryKey>
) {
  const result = useAuthenticatedMMAPIInfiniteQuery(
    [keyPrefix, input],
    async ({ pageParam: offset }: { pageParam?: number }) => {
      return mmApiClient.private.sales.active.query({
        ...input,
        offset,
      });
    },
    {
      ...(options ?? {}),
      getNextPageParam: (_lastPage, pages): number | null => {
        const totalCount = pages?.[0]?.totalCount;
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        return totalCount && totalCount > totalFetched ? totalFetched : null;
      },
    }
  );
  const totalCount = result.data?.pages?.[0]?.totalCount ?? null;
  const pages = result.data?.pages;
  const items = useMemo<ForSaleItem[]>(
    () =>
      pages?.reduce<{ items: ForSaleItem[]; set: Set<number> }>(
        (acc, page) => {
          page.items.forEach((item) => {
            if (!acc.set.has(item.saleId)) {
              acc.items.push(item);
              acc.set.add(item.saleId);
            }
          });
          return acc;
        },
        { items: [], set: new Set() }
      )?.items ?? [],
    [pages]
  );

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    items,
    totalCount,
    fetchNextPageIfAvailable,
  };
}

export default function useForSaleListPaginated(
  params: ForSaleInput,
  options?: MmApiQueryOptions<ForSaleOutput, QueryKey>
) {
  const { data: countData } = useAuthenticatedMMAPIQuery(
    [keyPrefix, 'count', params],
    () => mmApiClient.private.sales.active.query({ ...params, offset: 0 }),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );

  const { data: itemData, ...queryResult } = useAuthenticatedMMAPIQuery(
    [keyPrefix, 'items', params],
    () => mmApiClient.private.sales.active.query(params),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );

  const data = useMemo(() => {
    return {
      items: itemData?.items,
      totalCount: countData?.totalCount,
    };
  }, [countData, itemData]);

  return { data, ...queryResult };
}
